import React, {useMemo, useRef} from "react";

export interface FormErrorContextProps {
    value: any;
    setError: (source: FormErrorReporterValue, error: boolean) => void;
}

export const FormErrorContext = React.createContext<FormErrorContextProps>({
    value: "",
    setError: () => {}
});

export type FormErrorReporterValue = string | number;

export type FormErrorReporterProps = {
    /** children to render inside form error context provider */
    children?: React.ReactNode,
    /**
     * callback, called whenever any *Input child of this provider reports its current error status
     * @param value the value as provided to this reporter.
     * @param error the current error state of this tab (true if any *Input beneath it has reported an error).
     */
    setError?: (value?: FormErrorReporterValue, error?: boolean) => void,
    /** the value associated with this reporter */
    value?: FormErrorReporterValue
}

/**
 * simple component for adding context in order to report form errors. Set a value for this reporter, and all *Input components rendered
 * anywhere in the provided children tree will report their error status, and cause the provided setError function to be called.
 */
export const FormErrorReporter = (
    {value, setError, children}: FormErrorReporterProps
): JSX.Element => {
    const errors = useRef({} as Record<FormErrorReporterValue, boolean>);
    const providerValue = useMemo(() => ({
        value,
        setError: (source: FormErrorReporterValue, error: boolean) => {
            errors.current[source] = error;
            setError?.(value, Object.values(errors.current).some((error) => error));
        }
    }), [value]);

    return (
        <FormErrorContext.Provider value={providerValue}>
            {children}
        </FormErrorContext.Provider>
    );
};

export default FormErrorReporter;