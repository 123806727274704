import React from 'react';
import PropTypes from 'prop-types';

import {PagerButtonConfig} from './PagerLogic';
import styles from './pagerStyles';

export interface PagerIconProps {
    config: PagerButtonConfig
    title: string
    Icon: any
}

const PagerIcon: React.FC<PagerIconProps> = ({config, title, Icon}) => {
    const classes = styles();

    return (
        <button
            className={`k-link k-pager-nav k-pager-first ${
                config.isDisabled ? `k-state-disabled ${classes.disabledIcon}` : ''
            }`}
            onClick={config.onClick}
        >
            <Icon className={`k-icon ${classes.icon}`} alt="" aria-label={title} />
        </button>
    );
};
PagerIcon.propTypes = {
    config: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    Icon: PropTypes.any.isRequired
};

export default PagerIcon;