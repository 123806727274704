import React, {Children} from "react";
import FormButtonToolbar from "../FormButtonToolbar/FormButtonToolbar";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import FormProvider from "../FormProvider/FormProvider";
import {createStyles} from "@mui/styles";
import {CommonFormProps} from "../interfaces";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {ClonableChildren} from "../../../utils/commonTypes";
import {Paper} from "@barracuda-internal/bds-core";

export const styles = createStyles({
    form: {
        display: "flex",
        flexDirection: (props: FormProps) => props.flexRow ? "row" : "column",
        flex: "1 1 auto",
        overflowY: "auto"
    },
    formContents: {
        flex: "1 1 auto",
        overflowY: "auto"
    },
    topButtonToolbar: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    }
});
const useStyles = makeOverrideableStyles("Form", styles);

export interface FormProps extends CommonFormProps {
    /** callback passed through to the FormButtonToolbar */
    back?: () => void,
    /**
     * Children to render in the form
     * All *Input components and the FormSection components are made to work seamlessly with Form and TabbedForm.
     */
    children?: ClonableChildren,
    /** if true, the form is not rendered inside a card */
    flat?: boolean,
    /** change the form `flex-direction` to `row`. This allows other elements to appear next to the form instead of below it */
    flexRow?: boolean,
    /**
     * boolean that when is true renders the toolbarButton on the topRight corner
     */
    topToolbar?: boolean
}

/**
 * A simple form connected to redux
 * Valid children of this component are Inputs, *Step components used in [Wizards](/?path=/docs/core-components-wizard-wizard--wizard), or any element
 * Also accepts all props for [useForm](/?path=/docs/core-hooks-form-hooks--page#)
 */
export const Form = (props: FormProps): JSX.Element => {
    const {
        children,
        formButtonToolbarProps,
        flat,
        topToolbar,
        noToolbar,
    } = props;
    const classes = useStyles(props);

    return (
        <ErrorBoundary>
            <FormProvider {...props}>
                {topToolbar &&
                    <div className={classes.topButtonToolbar}>
                        <FormButtonToolbar
                            {...formButtonToolbarProps}
                            buttonsOnly
                        />
                    </div>
                }
                {!flat ?
                    <Paper>
                        <form className={classes.form}>
                            <div className={classes.formContents}>
                                {Children.toArray(children).filter((child): child is React.ReactElement => !!child)}
                            </div>
                            {!noToolbar && !topToolbar && (
                                <FormButtonToolbar
                                    {...formButtonToolbarProps}
                                />
                            )}
                        </form>
                    </Paper> :
                    <form className={classes.form}>
                        <div className={classes.formContents}>
                            {Children.toArray(children).filter((child): child is React.ReactElement => !!child)}
                        </div>
                        {!noToolbar && !topToolbar && (
                            <FormButtonToolbar
                                {...formButtonToolbarProps}
                            />
                        )}
                    </form>
                }
            </FormProvider>
        </ErrorBoundary>
    );
};

Form.defaultProps = {
    submitOnEnter: true
};

export default Form;