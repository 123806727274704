import React, {createContext, useCallback, useEffect, useRef} from 'react';
import {
    FieldValues,
    FormProvider as ReactHookFormProvider,
    useForm as useReactHookForm,
    UseFormProps as UseReactHookFormProps
} from "react-hook-form";
import {cloneDeep, every, isEmpty} from "lodash";
import {useDeepCompareEffect} from "../../../hooks";
import {Prompt} from "react-router";
import {useTranslation} from 'react-i18next';
import {Subscription} from 'react-hook-form/dist/utils/createSubject';

/**
 * Takes a deep object and calls the callback with the full path and value of each property whose value is not an Object
 *
 * @param callback
 * @param input object to flatten
 * @param parent current path within object, used for recursion
 *
 * @example
 * {
 *     a: {
 *         c: "bar",
 *         d: [{e: "baz"}]
 *     },
 *     b: "foo"
 * }
 *
 * // order not guaranteed
 * calls will be: ("b", "foo"), ("a.c", "bar"), ("a.d.0.e": "baz")
 */
export const deepFlattenCallback = (callback: (key: string, value: any) => void, input: Record<string, any>, parent: string | undefined = undefined): void => {
    Object.keys(input).forEach((key) => {
        let propName = parent ? parent + '.' + key : key;
        if (typeof input[key] === 'object') {
            deepFlattenCallback(callback, input[key], propName);
        } else {
            input[key] && callback(propName, input[key]);
        }
    });
};

const isDeepEmpty = (value?: any): boolean => typeof value === "object" ? every(value, isDeepEmpty) : isEmpty(value);

type WatchCallback = (values?: any, initialValues?: any) => void;

export interface FormButtonToolbarContextProps {
    /** called when back button is clicked. If not provided, the back button is not rendered */
    back?: () => void,

    /** if true a reset button is showed on the toolbar in order to reset the temporary changes */
    canReset?: boolean,

    /** if true the button is disabled and a loading spinner is shown */
    disabled?: boolean,

    /** called when cancel button is clicked. If not provided, the cancel button is not rendered */
    onCancel?: () => void,

    /** if true the button is disabled since no fields in the form have been modified yet */
    pristine?: boolean,

    /** if true onEnter submits the values */
    submitOnEnter?: boolean,

    /** a submission handler generator */
    handleSubmitWithOptions?: (options?: any) => (event?: any) => Promise<void>;

    /** add a subscription to listen for form changes */
    watch?: (callback: WatchCallback, initialize?: boolean) => Subscription;
}

export const FormPropsContext = createContext<FormButtonToolbarContextProps>({});

export type FormProviderProps = {
    /** children to render within the context of this provider */
    children?: React.ReactNode,

    /** if true, all values in the form will be cleared in the event of a successful CRUD request */
    clearOnSuccess?: boolean,

    /** props to pass into react-hook-form */
    hookFormProps?: Partial<UseReactHookFormProps>,

    /** optional errors to display on initialization */
    initialErrors?: any,

    /** default values for each input, referenced by their source, may be deep for complex inputs */
    initialValues?: FieldValues,

    /**
     * callback whenever the form values are changed.
     *
     * @param formValues - the complete value of the form
     * @param initialValues - the initial values of the form
     */
    onChange?: (formValues: FieldValues, initialValues?: FieldValues) => void,

    /**
     * called after a successful invocation of the save callback
     *
     * @param saveValue the value resolved by the promise returned from save
     */
    onSubmitSuccess?: (formValues: FieldValues) => void,

    /**
     * called with the form values upon form submission, provided validation has succeeded
     *
     * @param  formValues the complete value of the form
     */
    save?: (formValues: FieldValues, options?: any) => Promise<FieldValues>,

    /**
     * validates the current form values before calling save with them
     *
     * @param formValues the complete value of the form
     */
    validate?: (formValues: FieldValues) => FieldValues

    /** if true the form components are not editable */
    disabled?: boolean,

    /**
     *  if true, removes the browser dialog that is shown if the user attempts to navigate away from the page when the form is dirty.
     *  Required if you are not using Routing.
     */
    allowDirtyNavigation?: boolean,
}

/**
 * Provides contexts for form inputs and validators
 * Designed to be used internally by Form and TabbedForm
 */
const FormProvider = ({children, ...props}: FormProviderProps): JSX.Element => {
    const {
        clearOnSuccess,
        initialErrors,
        initialValues = {},
        onChange,
        onSubmitSuccess,
        save,
        validate,
        hookFormProps,
        allowDirtyNavigation
    } = props;
    const methods = useReactHookForm({defaultValues: cloneDeep(initialValues), ...hookFormProps});
    const {
        reset,
        setError,
        clearErrors,
        formState: {submitCount, isSubmitSuccessful, isSubmitted, dirtyFields},
        handleSubmit,
        trigger,
        watch,
    } = methods;
    const [translate] = useTranslation();
    const watchExtraData = useRef({initialValues, props});
    watchExtraData.current.initialValues = initialValues;
    watchExtraData.current.props = props;
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    // set errors from crud
    const handleErrors = (response: any): void => {
        deepFlattenCallback((key, message) => {
            setError(key, {message});
        }, response);
    };

    const expandedWatch = useCallback((callback, initialize = true) => {
        // Do initial call if set
        initialize && callback(methods.getValues(), watchExtraData.current.initialValues, watchExtraData.current.props);
        return watch((formData) => callback(formData, watchExtraData.current.initialValues, watchExtraData.current.props));
    }, []);

    useEffect(() => {
        // Do onChange callback immediately on first render to initialise. The subscription setup below will be used to watch for more changes.
        onChangeRef.current?.(methods.getValues(), watchExtraData.current.initialValues);
    }, []);

    // Subscribe to watch form changes, and call onChange if provided.
    useDeepCompareEffect(() => {
        const subscription = expandedWatch((formValues: FieldValues, initialValues: FieldValues) => {
            onChangeRef.current?.(formValues, initialValues);
            // revalidate the form when the values change, only if it's already been submitted
            if (isSubmitted && !isSubmitSuccessful) {
                trigger();
            }
        }, false);
        return () => subscription.unsubscribe();
    }, [isSubmitted, isSubmitSuccessful, trigger]);

    // clear form after submission
    useEffect(() => {
        if (isSubmitSuccessful && clearOnSuccess) {
            reset();
        }
    }, [submitCount, isSubmitSuccessful, clearOnSuccess]);

    // initialize form with values if they arrive after first render
    useDeepCompareEffect(() => {
        initialValues && reset(cloneDeep(initialValues));
        if (initialValues && initialErrors) {
            handleErrors(initialErrors);
        }
    }, [initialValues]);

    const handleSubmitWithOptions = useCallback((options?: any) => (event?: any): Promise<void> => {
        clearErrors();
        return handleSubmit(
            async (values: any) => {
                // run form level validation before attempting to submit
                const formValidationErrors = validate?.(values);
                if (!isDeepEmpty(formValidationErrors)) {
                    return await Promise.reject(formValidationErrors)?.then(onSubmitSuccess, handleErrors);
                }
                return await save?.(values, options)?.then(onSubmitSuccess, handleErrors);
            }
        )(event).catch(handleErrors);
    }, [onSubmitSuccess, validate, save]);

    return (
        <ReactHookFormProvider {...methods}>
            <FormPropsContext.Provider value={{...props, handleSubmitWithOptions, watch: expandedWatch}}>
                {!allowDirtyNavigation && (
                    <Prompt
                        message={translate("cuda.form.dirtyNavigationCheck")}
                        // TODO: Had to swap from isDirty, because it was saying forms were dirty when they werent.. no idea why.
                        when={Object.values(dirtyFields).length > 0}
                    />
                )}
                {children}
            </FormPropsContext.Provider>
        </ReactHookFormProvider>
    );
};

export default FormProvider;