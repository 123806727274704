import {useCallback, useEffect, useState} from "react";
import {TOptions} from "i18next";
import {useCustomEvents, usePostCustomEvent} from "./CustomEventHooks";
import {uniqBy} from "lodash";

export enum NotificationEventType {
    SHOW = "CUDA/NOTIFICATION_SHOW",
    HIDE = "CUDA/NOTIFICATION_HIDE"
}

export enum NotificationDisplayType {
    BANNER = "CUDA/NOTIFICATION_BANNER",
    DIALOG = "CUDA/NOTIFICATION_DIALOG",
    TOAST = "CUDA/NOTIFICATION_TOAST"
}

export enum NotificationLevel {
    INFO = "CUDA/INFO_NOTIFICATION",
    OK = "CUDA/OK_NOTIFICATION",
    WARNING = "CUDA/WARNING_NOTIFICATION",
    ERROR = "CUDA/ERROR_NOTIFICATION"
}

export type BaseNotificationParams = {
    translateParams?: TOptions
}
export type BannerNotificationParams = BaseNotificationParams & {
    content?: string,
    level?: NotificationLevel
    dismissible?: boolean,
    spinner?: boolean,
    onDismiss?: () => void
};
export type DialogNotificationParams = BaseNotificationParams & {
    content?: string | [string]
};
export type ToastNotificationParams = BaseNotificationParams & {
    content?: string,
    level?: NotificationLevel,
    duration?: number
};
export type NotificationEventBase = {
    event: NotificationEventType,
    display: NotificationDisplayType,
    params?: BannerNotificationParams | DialogNotificationParams | ToastNotificationParams
}
export type NotificationHide = NotificationEventBase & {
    event: NotificationEventType.HIDE,
    params?: { content?: string | [string] }
}
export type NotificationShowBanner = NotificationEventBase & {
    event: NotificationEventType.SHOW,
    display: NotificationDisplayType.BANNER,
    params: BannerNotificationParams
}
export type NotificationShowDialog = NotificationEventBase & {
    event: NotificationEventType.SHOW,
    display: NotificationDisplayType.DIALOG,
    params: DialogNotificationParams
}
export type NotificationShowToast = NotificationEventBase & {
    event: NotificationEventType.SHOW,
    display: NotificationDisplayType.TOAST,
    params: ToastNotificationParams
}
export type NotificationEvent =
    NotificationHide
    | NotificationShowBanner
    | NotificationShowDialog
    | NotificationShowToast;
export const NotificationEventKey = "CUDA/NOTIFICATION_EVENT";

export type NotificationRecord = {
    [NotificationDisplayType.DIALOG]: DialogNotificationParams[],
    [NotificationDisplayType.BANNER]: BannerNotificationParams[],
    [NotificationDisplayType.TOAST]: ToastNotificationParams[],
}

const useNotificationEvents = (notificationHandler: (event?: NotificationEvent) => void) => useCustomEvents<NotificationEvent>(NotificationEventKey, notificationHandler);
/**
 * Monitors notification events, and returns the active banners, dialogs and toasts.
 * For internal use by NotificationDialog, which should only be rendered once per app layout.
 * Please use "usePostNotification" to create notifications.
 */
export const useManageNotifications = (): [NotificationRecord, (eventData?: (NotificationEvent | undefined)) => void] => {
    const [notifications, setNotifications] = useState<NotificationRecord>({
        [NotificationDisplayType.DIALOG]: [],
        [NotificationDisplayType.BANNER]: [],
        [NotificationDisplayType.TOAST]: []
    });
    const notificationHandler = useCallback((notification?: NotificationEvent) => {
        if (notification) {
            if (notification.display === NotificationDisplayType.DIALOG) {
                setNotifications((current) => ({
                    ...current,
                    [NotificationDisplayType.DIALOG]: notification.event === NotificationEventType.SHOW ? [notification.params] : []
                }));
            } else if (notification.event === NotificationEventType.SHOW) {
                setNotifications((current) => ({
                    ...current,
                    [notification.display]: uniqBy([...current[notification.display], notification.params], ({content}) => content)
                }));
            } else {
                setNotifications((current) => ({
                    ...current,
                    [notification.display]: !notification.params?.content ? [] : current[notification.display].filter((existing) => existing.content !== notification.params?.content)
                }));
            }
        }
    }, []);
    const postNotification = useNotificationEvents(notificationHandler);

    return [notifications, postNotification];
};

export const usePostNotification = () => usePostCustomEvent<NotificationEvent>(NotificationEventKey);

/**
 * Hook for creating a banner that is always visible when the calling component is visible.
 *
 * Creates a banner notification on first render, and hides it when unmounted.
 *
 * @function
 * @param {string} content the notification content to use when showing (default is content provided on the hook invocation).
 * @param {string} level the notification level to use when showing (default is level provided on the hook invocation).
 * @param {boolean} dismissible controls if banner notifications are dismissable.
 * @param {boolean} spinner controls if banner notifications display a spinner.
 * @param {object} translateParams object to provide to the content i18n translation call.
 */
export const usePageBanner = (content: string, level: NotificationLevel = NotificationLevel.INFO, dismissible: boolean = true, spinner: boolean = false, translateParams?: TOptions): void => {
    const postNotification = usePostNotification();
    useEffect(() => {
        postNotification({
            event: NotificationEventType.SHOW,
            display: NotificationDisplayType.BANNER,
            params: {
                content,
                level,
                dismissible,
                spinner,
                translateParams
            }
        });
        return () => postNotification({
            event: NotificationEventType.HIDE,
            display: NotificationDisplayType.BANNER,
            params: {content}
        });
    });
};