import React, {useMemo, useCallback} from 'react';

export interface PagerPageConfig {
    isActive: boolean
    page: number
    onClick: (e: any) => void
}

export interface PagerButtonConfig {
    isDisabled: boolean
    onClick: (e: any) => void
}

export interface PagerProps {
    total: number
    skip: number
    take: number
    onPageChange: (e: any) => void
    info: boolean
    buttonCount: number
    pageSizes?: number[]
}

export interface PagerMenuItem {
    id: number
    value: number
}

export interface WrappedComponentProps extends PagerProps {
    i18nTexts?: {
        itemsPerPage?: string
        gotoFirstPage?: string
        gotoPrevPage?: string
        gotoNextPage?: string
        gotoLastPage?: string
    }
    from: number
    to: number
    pages: PagerPageConfig[]
    buttons: {
        [key: string]: PagerButtonConfig
    }
    itemsPerPageConfig?: {
        onChange: (e: any) => void
        value: number
        menuItems: PagerMenuItem[]
    }
    dotsStatuses: {
        isPrevVisible: boolean
        isNextVisible: boolean
    }
    maxPage: number
}

export default (WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
    const PagerLogic: React.FC<PagerProps> = (props) => {
        const {total, skip, take, onPageChange, buttonCount, pageSizes} = props;
        const OFFSET_RANGE = Math.floor(buttonCount / 2);
        const ADDITIONAL_OFFSET = buttonCount % 2;

        const from = useMemo(() => 1 + skip, [skip]);

        const to = useMemo(() => Math.min(from + take - 1, total), [from, take, total]);

        const currentPage = useMemo(() => skip / take, [take, skip]);

        const maxPage = useMemo(() => Math.ceil(total / take), [total, take]);

        const placeOnChangeEvent = useCallback(
            (event: any, newSkip: number) => {
                if (newSkip !== skip) {
                    onPageChange({
                        target: null,
                        skip: newSkip,
                        take,
                        syntheticEvent: event,
                        nativeEvent: event.nativeEvent
                    });
                }
            },
            [onPageChange, skip, take]
        );

        const pages = useMemo((): PagerPageConfig[] => {
            const offsets: PagerPageConfig[] = [...Array(maxPage).keys()].map((index: number) => ({
                isActive: currentPage === index,
                page: index + 1,
                onClick: (event: any) => {
                    placeOnChangeEvent(event, index * take);
                }
            }));

            const startOffsetfromStart = Math.max(currentPage - OFFSET_RANGE, 0);
            const startOffsetDiff = Math.abs(currentPage - OFFSET_RANGE - startOffsetfromStart);
            let collectedOffsets = offsets.slice(startOffsetfromStart, startOffsetfromStart + OFFSET_RANGE - startOffsetDiff);

            const endOffsetFromEnd = Math.min(currentPage + OFFSET_RANGE, offsets.length - ADDITIONAL_OFFSET);
            const endOffsetDiff = Math.abs(currentPage + OFFSET_RANGE - endOffsetFromEnd);
            const takenFromEnd = endOffsetFromEnd - currentPage + ADDITIONAL_OFFSET;
            collectedOffsets = [...collectedOffsets, ...offsets.slice(currentPage, currentPage + takenFromEnd)];

            if (startOffsetDiff) {
                const newEndOffset = currentPage + takenFromEnd;
                collectedOffsets = [...collectedOffsets, ...offsets.slice(newEndOffset, newEndOffset + startOffsetDiff)];
            }

            if (endOffsetDiff && startOffsetfromStart) {
                const newStartOffset = Math.max(startOffsetfromStart - endOffsetDiff, 0);
                collectedOffsets = [...offsets.slice(newStartOffset, newStartOffset + endOffsetDiff), ...collectedOffsets];
            }

            return collectedOffsets;
        }, [take, currentPage, placeOnChangeEvent, maxPage, OFFSET_RANGE, ADDITIONAL_OFFSET]);

        const buttons = useMemo(() => ({
                firstPage: {
                    isDisabled: !currentPage,
                    onClick: (event: any) => {
                        placeOnChangeEvent(event, 0);
                    }
                },
                prevPage: {
                    isDisabled: !currentPage,
                    onClick: (event: any) => {
                        placeOnChangeEvent(event, skip - take);
                    }
                },
                nextPage: {
                    isDisabled: currentPage === maxPage - 1,
                    onClick: (event: any) => {
                        placeOnChangeEvent(event, skip + take);
                    }
                },
                lastPage: {
                    isDisabled: currentPage === maxPage - 1,
                    onClick: (event: any) => {
                        placeOnChangeEvent(event, (maxPage - 1) * take);
                    }
                }
            }), [currentPage, maxPage, placeOnChangeEvent, skip, take]);

        const itemsPerPageConfig = useMemo(() => {
            if (!pageSizes) {
                return undefined;
            }

            return {
                onChange: (event: any) => {
                    onPageChange({
                        target: null,
                        skip: 0,
                        take: event.target.value,
                        syntheticEvent: event,
                        nativeEvent: event.nativeEvent
                    });
                },
                value: take,
                menuItems: pageSizes.map((size: number) => ({
                    id: size,
                    value: size
                }))
            };
        }, [pageSizes, onPageChange, take]);

        const dotsStatuses = useMemo(() => ({
                isPrevVisible: (pages[0] || {}).page !== 1,
                isNextVisible: ([...pages].pop() || {}).page !== maxPage
            }), [pages, maxPage]);

        return useMemo(() => (
            // @ts-ignore
                <WrappedComponent
                    {...props}
                    from={from}
                    to={to}
                    buttons={buttons}
                    pages={pages}
                    itemsPerPageConfig={itemsPerPageConfig}
                    dotsStatuses={dotsStatuses}
                    maxPage={maxPage}
                />
            ), [props, from, to, buttons, pages, itemsPerPageConfig, dotsStatuses, maxPage]);
    };

    return PagerLogic;
};