import {createContext, useContext} from "react";

export interface OnConsentChangedEvent {
    detail: string[]
}

declare global {
    interface Window {
        Optanon?: {
            ToggleInfoDisplay: () => void;
            OnConsentChanged: (handler: (event: OnConsentChangedEvent) => void) => void
            IsAlertBoxClosedAndValid: () => boolean;
            RejectAll: () => void;
        };
        OptanonActiveGroups?: string;
    }
}

/**
 * OneTrust context props
 */
export interface OneTrustContextProps {
    /** is true if OneTrust API has been added to global window object */
    enabled: boolean;
    /** is true if OneTrust cookie banner has been accepted/closed */
    completed: boolean;
    /** list of the current allowed OneTrust groups */
    allowedGroups: string[];
    /** callback to reopen the Cookie management popup */
    reopenPopup: () => void;
}

/**
 * OneTrust Context
 */
export const OneTrustContext = createContext<OneTrustContextProps>({
    enabled: false,
    completed: false,
    allowedGroups: [],
    reopenPopup: () => {}
});

/**
 * Hook exposing the OneTrust context
 */
export const useOneTrust = (): OneTrustContextProps => useContext(OneTrustContext);