import {Typography} from "@barracuda-internal/bds-core";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {BarracudaIcon} from "@cuda-react/icons";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {useOneTrust} from "../../../hooks";

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        padding: "5px 15px",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopColor: theme.palette.divider,
        borderTopStyle: "solid",
        borderTopWidth: 1
    },
    footerText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
        textDecoration: "none"
    },
    footerLink: {
        color: theme.palette.primary.main
    },
    cookieLink: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "underline"
    },
    barracudaLogo: {
        height: 25,
        width: 105
    }
});
const useStyles = makeOverrideableStyles("Footer", styles);

export type FooterProps = {
    /**
     * additional item/s to render in footer description.
     */
    footerItems?: ReactNode
};

/**
 * Creates a simple Barracuda branded footer, with Privacy Policy link and copyright.
 */
export const Footer = (props: FooterProps): JSX.Element => {
    const {footerItems} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const year = new Date().getFullYear();
    const {enabled, reopenPopup} = useOneTrust();

    return (
        <div className={classes.root}>
            <Typography className={classes.footerText}>
                <a className={classes.footerLink} href='https://www.barracuda.com/legal/privacy'
                   rel='noopener noreferrer' target='_blank'>
                    {translate("cuda.footer.privacyPolicy")}
                </a>
                &nbsp;
                {enabled && (
                    <>
                        {"| "}
                        <a className={classes.cookieLink} onClick={reopenPopup}>
                            {translate("cuda.footer.cookies")}
                        </a>
                    </>
                )}
                &nbsp; | © {year} {translate("cuda.footer.copyright")} {footerItems || null}
            </Typography>
            <div>
                <a href='https://barracuda.com/' rel='noopener noreferrer' target='_blank'>
                    <BarracudaIcon classes={{icon: classes.barracudaLogo}} />
                </a>
            </div>
        </div>
    );
};

export default Footer;