import {concat, get, set, uniqBy} from "lodash";
import {useContext, useEffect, useState} from "react";
import {getArrayDataContent, validateRequired, Validator} from "../utils";
import {useCrudProps} from "./CrudHooks";
import {useDeepCompareEffect} from "./UtilHooks";
import {CrudParams} from "../clients";
import {useFormState} from "react-hook-form";
import {FormPropsContext} from "../components/forms/FormProvider/FormProvider";
import {FormErrorContext} from "../components/forms/FormErrorReporter";
import {useTranslation} from "react-i18next";
import {InputLabelProps} from "../components/inputs/InputLabel/InputLabel";

/**
 * Options for the useChoices hook.
 */
export type UseChoicesOptions<Choice> = {
    /** array of choices that are known locally. */
    choices?: Choice[],
    /** filter key to use when calling resource for missing choices. Defaults to "key". */
    filterKey?: string,
    /** callback called whenever a new choice is added via the addChoice callback. It is called with a single argument, the choice added. */
    onAdd?: (choice: Choice) => void,
    /** dot-notation path to the value within the choice objects that should be used as an identifier (compared to selectedValues). Defaults to "key". */
    optionValue?: string,
    /** params to include when calling resource to collect missing choices. */
    params?: CrudParams,
    /** resource URL to collect missing choices from. */
    resource?: string
};

/**
 * The response from the useChoices hook.
 *
 * @property combinedChoices the combined choices from provided local choices and those gathered from the resource.
 * @property addChoice a function to add an additional choice to be stored locally.
 * @property loading the current loading status for the choices (if resource based).
 */
export type UseChoicesResponse<Choice> = [
    Choice[],
    (choice: Choice) => void,
    boolean
];

/**
 * Hook used to support the SelectArray component.
 *
 * It manages choices that can originate from a resource, but are cached locally, to prevent requesting all choices from the server.
 * It automatically requests from the resource choices that are currently selected but dont already exist in the known local choices.
 *
 * @function
 * @param selectedValues the currently selected values to be resolved to selected choices.
 * @param options hook configuration options.
 * @returns [selectedChoices, addChoice, loading]
 */
export const useChoices = <Choice extends object = any>(
    selectedValues: string[] = [],
    {choices = [], filterKey = "key", onAdd, optionValue = "key", params, resource}: UseChoicesOptions<Choice> = {}
): UseChoicesResponse<Choice> => {
    const [savedChoices, setSavedChoices] = useState<Choice[]>([]);

    const updateChoices = (newChoices: Choice[]) => setSavedChoices(
        (currentSavedChoices: Choice[]) => uniqBy(
            concat(newChoices, currentSavedChoices),
            (choice) => get(choice, optionValue)
        )
    );

    useDeepCompareEffect(() => {
        updateChoices(choices);
    }, [choices]);

    // array of ids for which we have no data
    const missingChoices = selectedValues.filter((value) => !savedChoices.some((choice) => get(choice, optionValue) === value));

    // data which was missing
    const fetchParams = {
        filter: {[filterKey]: missingChoices},
        ...params
    };

    const [resourceChoices, loading] = useCrudProps<Choice>(
        resource,
        fetchParams
    );

    // array containing all choices which we should need
    const combinedChoices = getArrayDataContent(resourceChoices).concat(savedChoices);

    // array of resultant choices which are relevant
    const result = selectedValues.map((value) => combinedChoices.find((choice: Choice) => get(choice, optionValue) === value) || set({}, optionValue, value));

    return [
        result,
        (choice) => {
            updateChoices([choice]);
            onAdd?.(choice);
        },
        loading
    ];
};


export interface InputWatchSettings extends Pick<InputLabelProps, "isRequired"> {
    /**
     * * A validator that checks the value is valid
     */
    validate?: Validator | Validator[],
    /**
     * function to determine if the input isRequired. If truthy is returned, ets whether the required '*' to the label and add the "required" validation.
     *
     * @function
     * @param {*} value the current value of the input.
     * @param {object} data the current value of the entire form.
     * @param {string} sourcePrefix the provided source prefix.
     * @param {*} siblingData the provided sibling data.
     * @param {object} initialValues the initial values of the entire form.
     * @returns {boolean} if truthy, the input will be set as required.
     */
    require?: (fieldValue: any, formData?: {
        [key: string]: any
    }, sourcePrefix?: string, siblingData?: any, initialValues?: { [key: string]: any }) => boolean,
    /**
     * function to determinate if input should be render or not.
     *
     * @function
     * @param {*} value the current value of the input.
     * @param {object} data the current value of the entire form.
     * @param {string} sourcePrefix the provided source prefix.
     * @param {*} siblingData the provided sibling data.
     * @param {object} initialValues the initial values of the entire form.
     * @returns {boolean} if truthy, the input will not be rendered.
     */
    hide?: (fieldValue: any, formData?: {
        [key: string]: any
    }, sourcePrefix?: string, siblingData?: any, initialValues?: { [key: string]: any }) => boolean,
    /**
     * if true, inputs will get disabled. Also passed to the input. Additionally it removes any active validation.
     */
    disabled?: boolean,
    /**
     * function that checks if input should be disabled. The result is passed to the input as 'disabled'. Additionally it removes any active validation if truthy.
     *
     * @function
     * @param {*} value the current value of the input.
     * @param {object} data the current value of the entire form.
     * @param {string} sourcePrefix the provided source prefix.
     * @param {*} siblingData the provided sibling data.
     * @param {object} initialValues the initial values of the entire form.
     * @returns {boolean} if truthy, the input will be disabled.
     */
    disable?: (fieldValue: any, formData?: {
        [key: string]: any
    }, sourcePrefix?: string, siblingData?: any, initialValues?: { [key: string]: any }) => boolean,
    /**
     * Prefix to apply to the source.
     */
    sourcePrefix?: string
}

/**
 * Hook used by Input for managing required/hidden/disabled state based on current form data, configuring validation object,
 * and retrieving current error state for a given input.
 * @param source input source
 * @param settings additional settings for input
 */
export const useInputWatch = (source: string, settings: InputWatchSettings = {}) => {
    const [dynamicDisabled, setDynamicDisabled] = useState(false);
    const [dynamicRequired, setDynamicRequired] = useState(false);
    const [dynamicHidden, setDynamicHidden] = useState(false);
    const formProps = useContext(FormPropsContext);

    const inputSource = settings.sourcePrefix ? settings.sourcePrefix + "." + source : source;
    useEffect(() => {
        const subscription = formProps?.watch?.((formData: any, defaultValues: any) => {
            const fieldValue = get(formData, inputSource);
            const siblingData = settings.sourcePrefix ? get(formData, settings.sourcePrefix) : undefined;
            setDynamicRequired(!!settings.require?.(fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues));
            setDynamicDisabled(!!settings.disable?.(fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues));
            setDynamicHidden(!!settings.hide?.(fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues));
        });
        return () => subscription?.unsubscribe?.();
    }, []);

    // Check for errors, and report them
    const {errors} = useFormState();
    const inputError = get(errors, inputSource);
    const tabErrorReporting = useContext(FormErrorContext);
    useEffect(() => {
        tabErrorReporting?.setError?.(inputSource, !!inputError);
        // return () => tabErrorReporting?.setError?.(inputSource, false);
    }, [!!inputError, inputSource]);

    // Get form data and props, and calculate states and validation
    const disabledByContext = formProps.disabled;
    const inputIsRequired = settings.isRequired || dynamicRequired;
    const inputIsDisabled = settings.disabled || disabledByContext || dynamicDisabled;
    const inputIsHidden = dynamicHidden;

    const [translate] = useTranslation();
    const validationArray = !inputIsDisabled && settings.validate ? [...(Array.isArray(settings.validate) ? settings.validate : [settings.validate])] : [];
    !inputIsDisabled && inputIsRequired && validationArray.unshift(validateRequired);
    const inputValidation = validationArray.reduce((acc, func, index) => ({
        [func.name || index]: (currentValue: any, formData: any) => {
            const response = func(currentValue, formData, {...formProps, t: translate}, source);
            return response ? JSON.stringify(response) : undefined;
        },
        ...acc
    }), {});

    return {
        inputSource,
        inputError,
        inputValidation,
        inputIsRequired,
        inputIsDisabled,
        inputIsHidden
    };
};