import React from 'react';
import {FormControl} from '@mui/material';

import {Select, SelectMenuItem, Typography} from "@barracuda-internal/bds-core";

import FirstPageIcon from '@barracuda-internal/bds-core/dist/Icons/Core/PageFirst';
import LastPageIcon from '@barracuda-internal/bds-core/dist/Icons/Core/PageLast';
import NextPageIcon from '@barracuda-internal/bds-core/dist/Icons/Core/PageNext';
import PrevPageIcon from '@barracuda-internal/bds-core/dist/Icons/Core/PagePrevious';

import PagerIcon from './PagerIcon';
import pagerLogic, {PagerMenuItem, PagerPageConfig, WrappedComponentProps} from './PagerLogic';
import styles from './pagerStyles';

const TEXTS = {
    itemsPerPage: 'Items per page',
    gotoFirstPage: 'Go to the first page',
    gotoPrevPage: 'Go to the previous page',
    gotoNextPage: 'Go to the next page',
    gotoLastPage: 'Go to the last page'
};

export const Pager: React.FC<WrappedComponentProps> = (props) => {
    const classes = styles();

    return (
        <div className="k-pager-wrap k-floatwrap k-widget k-grid-pager">
            <PagerIcon
                config={props.buttons.firstPage}
                title={props.i18nTexts?.gotoFirstPage || TEXTS.gotoFirstPage}
                Icon={FirstPageIcon}
            />
            <PagerIcon
                config={props.buttons.prevPage}
                title={props.i18nTexts?.gotoPrevPage || TEXTS.gotoPrevPage}
                Icon={PrevPageIcon}
            />
            {props.dotsStatuses.isPrevVisible && <strong className={classes.dots}>...</strong>}
            <ul className="k-pager-numbers k-reset">
                <li>
                    {props.pages.map((pageConfig: PagerPageConfig) => (
                        <button
                            key={pageConfig.page}
                            onClick={pageConfig.onClick}
                            className={`k-link ${pageConfig.isActive ? 'k-state-selected' : ''}`}
                        >
                            {pageConfig.page}
                        </button>
                    ))}
                </li>
            </ul>
            {props.dotsStatuses.isNextVisible && <strong className={classes.dots}>...</strong>}
            <PagerIcon
                config={props.buttons.nextPage}
                title={props.i18nTexts?.gotoNextPage || TEXTS.gotoNextPage}
                Icon={NextPageIcon}
            />
            <PagerIcon
                config={props.buttons.lastPage}
                title={props.i18nTexts?.gotoLastPage || TEXTS.gotoLastPage}
                Icon={LastPageIcon}
            />
            {props.itemsPerPageConfig && (
                <>
                  <span className={`k-pager-sizes k-label ${classes.itemsPerPage}`}>
                    {props.i18nTexts?.itemsPerPage || TEXTS.itemsPerPage}
                      &nbsp;&nbsp;
                  </span>
                    <FormControl variant="outlined" size="small">
                        <Select
                            onChange={props.itemsPerPageConfig.onChange}
                            value={props.itemsPerPageConfig.value}
                            renderValue={(): JSX.Element => (
                                <div>
                                    <Typography>{(props.itemsPerPageConfig || {}).value}</Typography>
                                </div>
                            )}
                        >
                            {props.itemsPerPageConfig.menuItems.map((menuItem: PagerMenuItem) => (
                                <SelectMenuItem key={menuItem.id} value={menuItem.id}>
                                    {`${menuItem.value}`}
                                </SelectMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
            {props.info && <div className="k-pager-info k-label">{`${props.from}-${props.to} of ${props.total}`}</div>}
        </div>
    );
};

export default pagerLogic(Pager);