import {get, merge, set} from "lodash";
import React, {useContext} from "react";
import {GlobalParamsContext} from "../components/providers/GlobalParamsProvider/GlobalParamsProvider";

/**
 * Hook for reading and/or managing a Global Parameter. Will cause a re-render whenever the value of the global parameter is changed.
 *
 * @function
 * @param source a dot notation path of the parameter you want to view/manage. Leave undefined if you want to view/manage all parameters (not recommended).
 * @returns [the current value of the provided global param (or undefined if not set), setter method to set the provided global param]
 */
export const useGlobalParam = (source?: string): [
        any | undefined,
    React.Dispatch<React.SetStateAction<any>>
] => {
    const {state, update} = useContext(GlobalParamsContext);
    const value = source ? get(state, source) : state;
    const updater = (valueOrUpdateFunction: React.Dispatch<React.SetStateAction<any>>) => update((currentState) => {
        if (source) {
            return merge({},
                currentState,
                set({}, source, typeof valueOrUpdateFunction === "function" ? valueOrUpdateFunction(get(state, source)) : valueOrUpdateFunction)
            );
        }
        return typeof valueOrUpdateFunction === "function" ? valueOrUpdateFunction({...currentState}) : valueOrUpdateFunction;
    });

    /**
     * @typedef globalParam
     * @type {array}
     * @property {*} value the current value of the target parameter.
     * @property {function} callback function to update the target parameter. The single argument (value) is used to replace the value currently stored for the target parameter.
     */
    return [value, updater];
};