import React from "react";
import {SvgIcon} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const styles = (theme: Theme) => ({
    icon: {},
    primaryFill: {
        fill: "#303030"
    },
    secondaryFill: {
        fill: theme.palette.primary.main
    }
});

const useStyles = makeStyles(styles);

const BarracudaIcon = (props: any) => {
    const classes = useStyles(props);
    return (
        <SvgIcon
            className={classes.icon + " cuda-react-icon"}
            viewBox="0 0 412.99 98.62"
            id="cuda-icon-barracuda"
        >
            <path
                className={classes.secondaryFill + " cuda-react-icon-secondary-fill"}
                d="M26.24,98.62H0C0,57,11,30.56,11,30.56,7.54,75.34,26.24,98.62,26.24,98.62ZM134.95,0S51.64,32.9,36.19,98.62h70.13S56,85.43,134.95,0Z"
            />
            <path
                className={classes.primaryFill + " cuda-react-icon-primary-fill"}
                d={
                    "M238.66,63.27v8.07h0.13a13.23,13.23,0,0,1,5-6.47,13,13,0,0,1,8-2.07v3.52a13.86,13.86,0,0,0-9.24,4,1" +
                    "1.49,11.49,0,0,0-2.63,4,13.24,13.24,0,0,0-.94,5V97.69h-4.2V63.27h3.87Zm-26.27,0V97.69h4.2V79.34a13.2" +
                    "1,13.21,0,0,1,.93-5,11.5,11.5,0,0,1,2.63-4,13.86,13.86,0,0,1,9.24-4V62.8a13,13,0,0,0-8,2.07,13.22,13" +
                    ".22,0,0,0-5,6.47h-0.13V63.27h-3.87ZM305.2,68a11.58,11.58,0,0,1,3.2,6.07h4.2q-0.74-5.94-4.57-8.87a15," +
                    "15,0,0,0-9.37-2.94,15.79,15.79,0,0,0-7,1.47,15.16,15.16,0,0,0-5.1,3.93,17,17,0,0,0-3.14,5.77,23.71,2" +
                    "3.71,0,0,0,0,14.08,16.94,16.94,0,0,0,3.14,5.77,14.83,14.83,0,0,0,5.1,3.9,16.11,16.11,0,0,0,7,1.43,13" +
                    ".48,13.48,0,0,0,9.61-3.5,15.87,15.87,0,0,0,4.6-9.77h-4.2a11.43,11.43,0,0,1-1.07,3.93,10.87,10.87,0,0," +
                    "1-2.2,3.1,9.93,9.93,0,0,1-3.07,2,9.33,9.33,0,0,1-3.67.73,10.64,10.64,0,0,1-5.27-1.27,11.57,11.57,0,0," +
                    "1-3.77-3.33,15.18,15.18,0,0,1-2.27-4.7,19.22,19.22,0,0,1,0-10.74,15.17,15.17,0,0,1,2.27-4.7,11.65,11." +
                    "65,0,0,1,3.77-3.33,10.64,10.64,0,0,1,5.27-1.27A9.16,9.16,0,0,1,305.2,68m39.28,29.68V63.27h-4.2V81.35a" +
                    "21.79,21.79,0,0,1-.6,5.1,14,14,0,0,1-1.87,4.43,9.92,9.92,0,0,1-3.2,3.1,8.87,8.87,0,0,1-4.67,1.17q-5,0" +
                    "-7-2.4t-2.17-7.07V63.27h-4.2V85.61a20.6,20.6,0,0,0,.67,5.47,10.48,10.48,0,0,0,2.1,4.07A9.27,9.27,0,0," +
                    "0,323,97.72a15.34,15.34,0,0,0,5.57.9,13.79,13.79,0,0,0,7-1.83,11.93,11.93,0,0,0,4.83-5.3h0.14v6.2h3.8" +
                    "7Zm36.28,0V50.06h-4.2V69.8h-0.14a10,10,0,0,0-1.9-3.27,12.09,12.09,0,0,0-2.84-2.37,12.64,12.64,0,0,0-3" +
                    ".43-1.43,15.31,15.31,0,0,0-3.77-.47,14.94,14.94,0,0,0-6.84,1.47,14.11,14.11,0,0,0-4.83,4A16.82,16.82," +
                    "0,0,0,350,73.5a26.45,26.45,0,0,0,0,13.94,16.79,16.79,0,0,0,2.87,5.8,13.74,13.74,0,0,0,4.83,3.93,15.24" +
                    ",15.24,0,0,0,6.84,1.43,13.8,13.8,0,0,0,3.77-.53,15.29,15.29,0,0,0,3.57-1.5,12.65,12.65,0,0,0,3-2.37,9" +
                    ".91,9.91,0,0,0,2-3.07h0.14v6.54h3.87Zm-26.91-11.9a22.52,22.52,0,0,1,0-10.61,14.65,14.65,0,0,1,2-4.71," +
                    "11,11,0,0,1,3.5-3.37,9.71,9.71,0,0,1,5.13-1.3,11.33,11.33,0,0,1,5.64,1.3,11.21,11.21,0,0,1,3.73,3.37," +
                    "13.76,13.76,0,0,1,2.06,4.71,22.31,22.31,0,0,1,0,10.61,13.72,13.72,0,0,1-2.06,4.7,11.14,11.14,0,0,1-3.7" +
                    "3,3.37,11.33,11.33,0,0,1-5.64,1.3,9.71,9.71,0,0,1-5.13-1.3,11,11,0,0,1-3.5-3.37,14.61,14.61,0,0,1-2-4" +
                    ".7M276.58,68.22a12.13,12.13,0,0,1,1.13,5.64V97.69h-4v-6h0a29.17,29.17,0,0,1-2.3,3,11.4,11.4,0,0,1-2.6" +
                    ",2.17,12,12,0,0,1-3.27,1.33,17.73,17.73,0,0,1-4.37.47,17.16,17.16,0,0,1-4.5-.57,10,10,0,0,1-3.66-1.8" +
                    ",8.55,8.55,0,0,1-2.47-3.13,10.39,10.39,0,0,1-.9-4.5,8.91,8.91,0,0,1,1.6-5.64,10.09,10.09,0,0,1,4.24-" +
                    "3.1,23.9,23.9,0,0,1,5.94-1.5q3.3-.43,6.71-0.84c0.89-.09,1.67-0.2,2.33-0.33a4.16,4.16,0,0,0,1.67-.7,3" +
                    ".27,3.27,0,0,0,1-1.37,6,6,0,0,0,.37-2.27,6,6,0,0,0-2.64-5.15,7.81,7.81,0,0,0-2.87-1.1,19.56,19.56,0,0" +
                    ",0-3.5-.3h-7.57V63l8.35-.19a24.37,24.37,0,0,1,4.29.42,11,11,0,0,1,4.07,1.63,8.9,8.9,0,0,1,2.93,3.37m-" +
                    "3.07,10.65h-0.13a2.4,2.4,0,0,1-1.54,1.07,15,15,0,0,1-2,.47q-2.67.47-5.5,0.84a30.18,30.18,0,0,0-5.17,1" +
                    ".1,10.15,10.15,0,0,0-3.84,2.1,5,5,0,0,0-1.5,3.9,6.11,6.11,0,0,0,.63,2.84,6.83,6.83,0,0,0,1.7,2.13,7.3" +
                    "8,7.38,0,0,0,2.47,1.37,9,9,0,0,0,2.87.46,14.43,14.43,0,0,0,4.6-.73,11.37,11.37,0,0,0,3.84-2.13,10.16," +
                    "10.16,0,0,0,3.57-7.94V78.88ZM203.82,68.22A12.15,12.15,0,0,1,205,73.86V97.69h-4v-6h0a28.79,28.79,0,0,1" +
                    "-2.3,3,11.39,11.39,0,0,1-2.6,2.17,12,12,0,0,1-3.27,1.33,17.7,17.7,0,0,1-4.37.47,17.13,17.13,0,0,1-4.5" +
                    "-.57,10,10,0,0,1-3.67-1.8,8.59,8.59,0,0,1-2.47-3.13,10.4,10.4,0,0,1-.9-4.5,8.91,8.91,0,0,1,1.6-5.64,1" +
                    "0.1,10.1,0,0,1,4.24-3.1,23.88,23.88,0,0,1,5.93-1.5q3.31-.43,6.71-0.84c0.89-.09,1.67-0.2,2.34-0.33a4.1" +
                    "5,4.15,0,0,0,1.66-.7,3.27,3.27,0,0,0,1-1.37,6,6,0,0,0,.37-2.27,6,6,0,0,0-2.64-5.15,7.82,7.82,0,0,0-2." +
                    "86-1.1,19.65,19.65,0,0,0-3.5-.3h-7.57V62.8h8.35a24.39,24.39,0,0,1,4.29.42,11,11,0,0,1,4.07,1.63,8.9,8" +
                    ".9,0,0,1,2.94,3.37m-3.07,10.65h-0.13a2.41,2.41,0,0,1-1.54,1.07,15.17,15.17,0,0,1-2,.47q-2.67.47-5.5,0" +
                    ".84a30,30,0,0,0-5.17,1.1,10.15,10.15,0,0,0-3.84,2.1,5,5,0,0,0-1.5,3.9,6.11,6.11,0,0,0,.63,2.84,6.9,6." +
                    "9,0,0,0,1.7,2.13,7.36,7.36,0,0,0,2.47,1.37,9,9,0,0,0,2.87.46,14.42,14.42,0,0,0,4.6-.73,11.36,11.36,0," +
                    "0,0,3.84-2.13,10.18,10.18,0,0,0,3.57-7.94V78.88Zm211.1-10.65A12.16,12.16,0,0,1,413,73.85V97.69h-4v-6h" +
                    "0a28.91,28.91,0,0,1-2.3,3,11.41,11.41,0,0,1-2.6,2.17,11.94,11.94,0,0,1-3.27,1.33,17.78,17.78,0,0,1-4." +
                    "37.47,17.12,17.12,0,0,1-4.5-.57,10,10,0,0,1-3.67-1.8,8.53,8.53,0,0,1-2.47-3.14,10.42,10.42,0,0,1-.9-4" +
                    ".5,8.91,8.91,0,0,1,1.6-5.64,10,10,0,0,1,4.24-3.1,24,24,0,0,1,5.93-1.5q3.3-.43,6.71-0.84a23.25,23.25,0" +
                    ",0,0,2.33-.33,4.09,4.09,0,0,0,1.67-.7,3.24,3.24,0,0,0,1-1.37,5.86,5.86,0,0,0,.37-2.27,6.07,6.07,0,0,0" +
                    "-2.64-5.15,7.81,7.81,0,0,0-2.87-1.1,19.55,19.55,0,0,0-3.5-.3h-7.57V62.8h8.35a24.25,24.25,0,0,1,4.29.4" +
                    "2,11,11,0,0,1,4.07,1.63,8.88,8.88,0,0,1,2.93,3.37m-3.07,10.65h-0.13a2.41,2.41,0,0,1-1.53,1.07,15.66,1" +
                    "5.66,0,0,1-2,.47q-2.67.47-5.5,0.83a30.38,30.38,0,0,0-5.17,1.1,10.14,10.14,0,0,0-3.83,2.11,5,5,0,0,0-1" +
                    ".5,3.9,6.1,6.1,0,0,0,.63,2.83,6.87,6.87,0,0,0,1.7,2.13,7.34,7.34,0,0,0,2.47,1.37,9,9,0,0,0,2.87.47,14" +
                    ".47,14.47,0,0,0,4.6-.73,11.36,11.36,0,0,0,3.84-2.13,10.41,10.41,0,0,0,2.6-3.4,10.31,10.31,0,0,0,1-4.5" +
                    "4V78.87ZM135,49.58v49h20.74a28.12,28.12,0,0,0,6.83-.72A18.86,18.86,0,0,0,167.4,96a10.59,10.59,0,0,0,3" +
                    ".13-2.64,12.78,12.78,0,0,0,1.78-3.06,13,13,0,0,0,.82-3,19.3,19.3,0,0,0,.21-2.65,11.93,11.93,0,0,0-2.8" +
                    "2-8.07A11.56,11.56,0,0,0,163,72.73V72.59a9.93,9.93,0,0,0,3.47-1.44,11.2,11.2,0,0,0,2.68-2.51,11.42,11" +
                    ".42,0,0,0,1.75-3.3,12.56,12.56,0,0,0-.69-9.48,10.57,10.57,0,0,0-3.47-3.71,14.34,14.34,0,0,0-5-2,29.69" +
                    ",29.69,0,0,0-5.94-.58H135Zm4.67,21.5V53.56h16.07q11.06,0,11.06,9.2a7.72,7.72,0,0,1-.69,3.23,7.36,7.36" +
                    ",0,0,1-2.06,2.65,10.17,10.17,0,0,1-3.43,1.78,16.26,16.26,0,0,1-4.88.65H139.69Zm0,23.56V75.06h16.07q6." +
                    "25,0,9.58,2.37a8.15,8.15,0,0,1,3.33,7.11,10.47,10.47,0,0,1-1,4.91,8.23,8.23,0,0,1-2.82,3.13,11.32,11." +
                    "32,0,0,1-4.12,1.61,26.32,26.32,0,0,1-4.95.45H139.69Z"
                }
            />
        </SvgIcon>
    );
};

export default BarracudaIcon;