import {useHistory} from "react-router-dom";
import {LocationDescriptor, Path} from "history";

let externalRedirectActive = false;

/**
 * just used for testing, shouldn't be called in an app.
 */
export const resetExternalRedirectActive = () => {
    externalRedirectActive = false;
};

/**
 * Returns  a "push" function for redirecting to a new url.
 * If the provided URL is a local URL (i.e. starts with a "/") it uses the local react-router-dom push method to perform
 * the redirect. Otherwise it will use window.location.href
 * Once an external redirect is processed, any subsequent redirect will be ignored to give the browser time to implement
 * the page change.
 */
export const useRedirect = (): ((url: Path | LocationDescriptor) => void) => {
    const {push} = useHistory() || {};

    const handlePush = (url: Path | LocationDescriptor) => {
        if (!externalRedirectActive) {
            if (typeof url !== "string" || url.startsWith("/")) {
                if (push) {
                    push(url);
                } else if (typeof url === "string") {
                    window.location.href = url;
                }
            } else {
                externalRedirectActive = true;
                window.location.href = url;
            }
        }
    };

    return handlePush;
};