import {CircularProgress, Collapse, IconButton, Paper, Typography} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import React from "react";
import {Close} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    defaults: {
        backgroundColor: theme.palette.primary.main
    },
    banner: {
        width: "100%",
        textAlign: "center",
        color: theme.palette.common.white,
        padding: "14px 30px 14px 30px",
        borderRadius: 0
    },
    typography: {
        verticalAlign: "middle",
        color: theme.palette.common.white,
        // @ts-ignore
        fontSize: theme.typography.htmlFontSize,
        display: "inline"
    },
    closeButton: {
        float: "right",
        padding: 0
    },
    closeIcon: {
        color: theme.palette.common.white
    },
    spinner: {
        marginRight: 15,
        verticalAlign: "middle",
        color: theme.palette.common.white
    }
});
const useStyles = makeOverrideableStyles("StatusBanner", styles);

export interface StatusBannerProps extends StyledComponentProps<typeof styles> {
    /**
     * the content to render within the banner.
     */
    children: JSX.Element,
    /**
     * if true, the StatusBanner has a dismiss/close icon, which calls the "onClose" callback when clicked.
     */
    dismissible?: boolean,
    /**
     * callback, called when the dismiss/close icon is clicked. The icon is only rendered if the "dismissible" prop is true.
     */
    onClose?: () => void,
    /**
     * if true, the banner is rendered open.
     */
    open: boolean,
    /**
     * if true, a loading spinner is shown (rendered to the left of the children content).
     */
    spinner?: boolean
}

/**
 * Renders a collapsible banner, with the provided children as the message content.
 *
 * Use the "open" prop to manage the visible state of the banner. You can also allow the banner to be dismissible by the user,
 * via the "dismissible" and "onClose" props.
 */
export const StatusBanner = (props: StatusBannerProps) => {
    const {dismissible, spinner, open, onClose, children} = props;
    const classes = useStyles(props);

    return (
        <Collapse in={open} unmountOnExit mountOnEnter>
            <Paper className={classNames(classes.banner, classes.defaults)}>
                {spinner && <CircularProgress size={20} className={classes.spinner}/>}
                <Typography className={classes.typography}>
                    {children}
                </Typography>
                {dismissible && onClose && (
                    <IconButton
                        className={classes.closeButton}
                        onClick={onClose}
                        size="small"
                    >
                        <Close id="cuda-icon-close" className={classes.closeIcon}/>
                    </IconButton>
                )}
            </Paper>
        </Collapse>
    );
};

StatusBanner.defaultProps = {
    dismissible: true,
    open: true
};

export default StatusBanner;