import {MutableRefObject, useEffect, useRef, useState} from "react";

export const useWidthTracking = (initialWidth = 1024): [MutableRefObject<any>, number] => {
    const ref = useRef<HTMLElement>(null);
    const [width, setWidth] = useState(initialWidth);
    const checkWidth = () => {
        if (ref.current) {
            setWidth(ref.current?.offsetWidth);
        }
    };

    useEffect(() => {
        checkWidth();
        window.addEventListener('resize', checkWidth);
        return () => window.removeEventListener('resize', checkWidth);
    }, []);

    return [ref, width];
};