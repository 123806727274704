import {Toolbar as MuiToolbar} from "@barracuda-internal/bds-core";
import React, {ReactElement} from "react";
import {CudaTheme, makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {ClonableChildren} from "../../../utils/commonTypes";

interface ToolbarChildProps {
    /**
     * if true the element will be rendered on the left instead of the right
     */
    left?: boolean;
}

interface BaseToolbarProps {
    /**
     * the tools to render into the toolbar. Usually buttons.
     */
    children: ClonableChildren<ToolbarChildProps>;
    /**
     * if true, all children are centered with no seperation between left and right children.
     * */
    center?: boolean;
}

const styles = (theme: Theme & typeof CudaTheme) => createStyles<string, BaseToolbarProps>({
    root: {
        width: `calc(100% - ${theme.spacing(4)})`,
        minHeight: 48,
        padding: theme.spacing(1, 0),
        margin: theme.spacing(0, 2),
        flexDirection: "row-reverse",
        borderStyle: "solid",
        borderColor: theme.palette.divider,
        borderWidth: "1px 0 0",
        "&> *:not(:first-child)": {
            marginRight: theme.spacing(1.5)
        },
        justifyContent: (props) => props.center ? "center" : undefined
    },
    leftRightSeparator: {
        marginLeft: "auto"
    }
});
const useStyles = makeOverrideableStyles("Toolbar", styles);

export interface ToolbarProps extends BaseToolbarProps, StyledComponentProps<typeof styles> {}

/**
 * Creates a standard toolbar, for form/dialog/wizard action buttons.
 */
export const Toolbar = (props: ToolbarProps) => {
    const classes = useStyles(props);
    const children = React.Children.toArray(props.children) as ReactElement<ToolbarChildProps>[];
    const leftChildren = children.filter((child) => child && child.props.left);
    const rightChildren = children.filter((child) => child && !child.props.left);

    return (
        <MuiToolbar className={classes.root} variant="regular">
            {rightChildren}
            {!props.center ? (<div className={classes.leftRightSeparator}/>) : null}
            {leftChildren}
        </MuiToolbar>
    );
};

export default Toolbar;