import {Button} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import SaveButton, {SaveButtonProps} from "../SaveButton/SaveButton";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useFormContext} from "react-hook-form";
import {FormPropsContext} from "../FormProvider/FormProvider";

const styles = (theme: Theme) => createStyles({
    formButton: {},
    buttonContainer: {
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
        display: "inline-flex",
        flexDirection: "row-reverse",
        "&> *:not(:first-child)": {
            marginRight: theme.spacing(1.5)
        },
        alignItems: "end"
    }
});
const useStyles = makeOverrideableStyles("FormButtonToolbar", styles);

export interface FormButtonToolbarProps extends SaveButtonProps, StyledComponentProps<typeof styles> {
    /** if true, the buttons are rendered without a Toolbar wrapping them */
    buttonsOnly?: boolean;
}

/**
 * FormButtonToolbar
 *
 * It is a simple toolbar that stays under the form. By Default it has the Save button, but can also have Reset (if reset is true to reset the temp changes),
 * Cancel (if onCancel is true) and back (if the prop back is true)
 */
const FormButtonToolbar = (props: FormButtonToolbarProps): JSX.Element => {
    const {buttonsOnly, classes: _ignored, ...saveButtonProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    // useFormContext is cast because handleSubmitWithOptions is a custom property
    const {reset, formState: {dirtyFields}} = useFormContext();
    const {
        back,
        canReset,
        handleSubmitWithOptions: handleSubmitWithRedirect,
        disabled,
        onCancel,
        submitOnEnter
    } = useContext(FormPropsContext);
    // TODO: Had to swap from isDirty, because it was saying forms were dirty when they werent.. no idea why.
    const pristine = Object.values(dirtyFields).length === 0;

    const toolbarContent = (
        <React.Fragment>
            <SaveButton {...{disabled, pristine, handleSubmitWithRedirect, submitOnEnter, ...saveButtonProps}}/>
            {canReset && reset && (
                <Button
                    onClick={() => reset()}
                    disabled={pristine || disabled}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.reset")}
                </Button>
            )}
            {onCancel && (
                <Button
                    onClick={onCancel}
                    disabled={disabled}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.cancel")}
                </Button>
            )}
            {back && (
                <Button
                    onClick={back}
                    disabled={disabled}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.formButton}
                >
                    {translate("cuda.buttons.back")}
                </Button>
            )}
        </React.Fragment>
    );

    return buttonsOnly ? (
        <div className={classes.buttonContainer}>
            {toolbarContent}
        </div>
    ) : (
        <Toolbar>
            {toolbarContent}
        </Toolbar>
    );
};

export default FormButtonToolbar;