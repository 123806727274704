import {browserIsIE} from "../../../utils";
import React, {useEffect} from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {useHistory} from "react-router-dom";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    unsupported: {
        fontSize: 32
    },
});
const useStyles = makeOverrideableStyles("UnsupportedPage", styles);

export interface UnsupportedPageProps extends StyledComponentProps<typeof styles> {}

/**
 * Basic page that displays unsupported browser message if current browser is not supported (is IE), or auto-redirects to home ("/") if browser is supported.
 *
 * This page is displayed by CudaReactApp instead of the normal content if an unsupported browser is detected on first load.
 */
export const UnsupportedPage = (props: UnsupportedPageProps) => {
    const unsupportedBrowser = browserIsIE();
    const [translate] = useTranslation();
    const {push} = useHistory();
    const classes = useStyles(props);

    useEffect(() => {
        !unsupportedBrowser && push("/");
    }, [unsupportedBrowser]);

    return unsupportedBrowser ? (
        <div className={classes.root} id="unsupported-page">
            <Typography variant="h1" className={classes.unsupported}>{translate("cuda.unsupported.browser")}</Typography>
        </div>
    ) : null;
};

export default UnsupportedPage;