import React from "react";
import {ClonableChildren} from "../../../utils/commonTypes";

export interface PropGatewayProps<Props extends object> {
    /**
     * The child/children to render. The children are cloned, and provided with only the props passed to this component that are defined by editProps.
     */
    children: ClonableChildren,
    /**
     * callback for filtering which props should be passed through to the child/children.
     * If not provided, no props are passed through to the child/children.
     *
     * @function
     * @param {object} props the props as provided to PropGateway (minus the children and editProps props).
     * @returns {object} the props to pass down to the child/children.
     */
    editProps?: (props: Omit<Props, "children" | "editProps">) => object
}

/**
 * This component allows you to drop or customise the props that are passed between a parent component and its
 * children.
 *
 * If no editProps function is provided, then no props from PropGateway are passed down to the children
 *
 * If editProps is provided, it will be called with all props that are passed to PropGateway, and the
 * returned props will be provided to the children.
 */
const PropGateway = <Props extends PropGatewayProps<Props>>({children, editProps, ...props}: Props) => (
    <React.Fragment>
        {
            React.Children.toArray(children)
                .filter((child): child is React.ReactElement => !!child)
                .map((child) => React.cloneElement(child, editProps ? editProps(props) : {}))
        }
    </React.Fragment>
);

export default PropGateway;