import {IconButton, Menu, MenuItem, Tab as BdsTab, Tabs as BdsTabs, Typography} from "@barracuda-internal/bds-core";
import React, {Children, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {difference} from "lodash";
import classNames from "classnames";
import {TabProps} from "../Tab/Tab";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {ClonableChildren} from "../../../utils/commonTypes";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useWidthTracking} from "../../../utils/useWidthTracking";

interface BaseTabsProps {
    /**
     * for each child provided, one tab button is created. The children are then rendered, and provided with a "value" and "activeValue" prop.
     * These are simply the current child index, and the index of the current selected tab, and can be used by the child to determine whether to render or not.
     * The [Tab](/?path=/docs/core-components-tabs-tab--tab) has been created to seamlessly work with both Tabs and [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs).
     *
     * The tab button that is rendered is determined by props added to each child. Additional behaviour and props are implemented by the [Tab](/?path=/docs/core-components-tabs-tab--tab),
     * see it's page for more information.
     */
    children: ClonableChildren<TabProps>,
    /**
     * if true, reduces the min width that the tab buttons can compress to.
     */
    compact?: boolean,
    /**
     * Callback function called whenever the active tab has changed
     * @param index
     */
    onChange?: (index: number| string) => void,
    /**
     * The currently active tab
     */
    activeTab?: number | string
    /**
     * a single or array of children to render as actions. Usually these are buttons.
     */
    actions?: React.ReactNode | Array<React.ReactNode>,
    /**
     * if true, when there are too many tabs to fit on screen, a ... menu is displayed to allow selection of the additional
     * tabs
     */
    condensedMenu?: boolean
}

interface StyleTabsProps extends BaseTabsProps {
    /** the current number of tabs visible */
    visibleTabCount: number
}

export const styles = (theme: Theme) => createStyles<string, StyleTabsProps>({
    tabRow: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center"
    },
    tabs: {
        minWidth: (props: any) => props.visibleTabCount * 60,
        flexGrow: 1
    },
    tabsFlexContainer: {
        alignItems: "center"
    },
    tab: {
        width: "max-content",
        padding: theme.spacing(0, 2),
    },
    tabMenu: {
        // flexGrow: 1
    },
    actions: {
        display: "flex",
        flexShrink: 0
    },
    tabTitleError: {
        color: theme.palette.error.main
    },
    tabIcon: {
        minHeight: 32
    },
    tabStatus: {
        whiteSpace: "nowrap",
        minHeight: 32
    },
    tabStatusIcon: {
        "& > svg": {
            position: "relative",
            top: theme.spacing(0.5),
            marginRight: theme.spacing(1)
        }
    },
    tabStatusText: {
        display: "inline"
    }
});

const useStyles = makeOverrideableStyles("Tabs", styles);

export interface TabsProps extends StyledComponentProps<typeof styles>, BaseTabsProps {}

export const Tabs = (props: TabsProps) => {
    const {children, activeTab, onChange, actions, condensedMenu} = props;
    const [tabsRef, tabsWidth] = useWidthTracking(0);
    const [translate] = useTranslation();
    const childArray = Children
        .toArray(children)
        .filter((child): child is React.ReactElement => !!child)
        .map((child, index) => (child.props.value ? child : React.cloneElement(child, {value: index})));
    const [localSelectedTab, setLocalSelectedTab] = useState(childArray[0].props.value);
    const selectedTab = activeTab !== undefined ? activeTab : localSelectedTab;

    // Calculate max number of tabs to show, according to screen size
    const [anchorElementMenu, setAnchorElementMenu] = useState<HTMLButtonElement | null>(null);
    const maxChildren = Math.floor((tabsWidth - 30) / (props.compact ? 60 : 160));
    const activeTabIndex = childArray.findIndex((child: any) => child?.props?.value === selectedTab);
    const start = Math.max(0, Math.min(childArray.length - maxChildren, activeTabIndex - Math.floor(maxChildren / 2)));
    const displayedTabs = condensedMenu ? childArray.slice(start, start + maxChildren) : childArray;
    const notDisplayedTabs = difference(childArray, displayedTabs);
    const cropped = notDisplayedTabs.length > 0;
    const visibleTabCount = displayedTabs.length;
    const compact = props.compact || (visibleTabCount * 160 > tabsWidth);
    const classes = useStyles({
        ...props,
        compact,
        visibleTabCount
    });

    const handleChange = (event: any, newValue: any) => {
        setLocalSelectedTab(newValue);
        onChange?.(newValue);
    };
    const tabsHaveStatus = childArray.some((tab) => tab.props.status);
    const tabsHaveIcons = childArray.some((tab) => tab.props.icon);

    return (
        <div>
            <div className={classes.tabRow}>
                <BdsTabs
                    id="cuda-react-tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    value={selectedTab}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{flexContainer: classes.tabsFlexContainer}}
                    ref={tabsRef}
                >
                    {displayedTabs.map((tab) => {
                        const label = (
                            <span>
                                {tab.props.icon && tabsHaveStatus ? tab.props.icon : null}
                                {translate(tab.props.label)}
                            </span>
                        );
                        const icon = tabsHaveStatus ? (
                            <span style={{color: tab.props.statusColor}} className={classes.tabStatus}>
                                {tab.props.status && tab.props.statusIcon ? (
                                    <span className={classes.tabStatusIcon}>
                                        {React.cloneElement(tab.props.statusIcon, tab.props.statusIconProps)}
                                    </span>
                                ) : null}
                                {tab.props.status ? (
                                    <Typography className={classes.tabStatusText} style={{color: tab.props.statusColor}}>
                                        {translate(tab.props.status)}
                                    </Typography>
                                ) : null}
                            </span>
                        ) : (tabsHaveIcons ? (
                            <span className={classes.tabIcon}>
                                {tab.props.icon || null}
                            </span>
                        ) : null);

                        return (
                            <BdsTab
                                wrapped
                                className={classNames(
                                    classes.tab,
                                    tab.props.error && classes.tabTitleError
                                )}
                                key={tab.props.value}
                                // @ts-ignore Not sure what this error is about, needs investigating
                                icon={tabsHaveStatus ? label : icon}
                                value={tab.props.value}
                                label={tabsHaveStatus ? icon : label}
                                disabled={tab.props.disabled}
                                sx={{
                                    minWidth: compact ? 60 : 160,
                                    maxWidth: `${100.0 / visibleTabCount}%`,
                                }}
                            />
                        );
                    })}
                    {cropped && condensedMenu ? (
                        <div className={classes.tabMenu}>
                            <IconButton
                                size="small"
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => {
                                    setAnchorElementMenu(event.currentTarget);
                                }}
                                className={classes.moreIconButton}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorElementMenu}
                                keepMounted
                                open={Boolean(anchorElementMenu)}
                                onClose={() => {
                                    setAnchorElementMenu(null);
                                }}
                            >
                                {notDisplayedTabs.map((child: any, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            handleChange({}, child?.props?.value);
                                            setAnchorElementMenu(null);
                                        }}
                                    >
                                        {child?.props?.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    ) : null}
                </BdsTabs>
                {actions ? <div className={classes.actions}>{actions}</div> : null}
            </div>
            {childArray.map((tab) => React.cloneElement(tab, {
                    key: tab.props.value,
                    activeValue: selectedTab,
                }))}
        </div>
    );
};

export default React.memo(Tabs);