import {Grid} from "@barracuda-internal/bds-core";
import React, {Children} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import classNames from "classnames";
import {createStyles} from "@mui/styles";
import {GridSize} from "@mui/material";

export const filterItemsForColumn = (items: (React.ReactElement | null)[], currentColumn: number, totalColumns: number) =>
    items.filter((child) =>
        child?.props?.column === currentColumn ||
        (currentColumn + 1 === totalColumns && !(child?.props?.column < currentColumn))
    );

export interface ColumnsBaseProps {
    /**
     * allow columns to expand to fit content larger than their specified width would otherwise allow
     */
    autoWidth?: boolean,
    /**
     * Styles to be passed through to the root element
     * @ignore
     */
    className?: string,
    /**
     * the children to render in columns. The column the child is assigned to should be provided by a "column" prop. If one is not
     * provided, then the child will be rendered into the last column.
     *
     * @param {number} props.column the column to assign this child to.
     */
    children?: React.ReactElement | (React.ReactElement | null)[] | null,
    /**
     * column/s definition.
     */
    columns?: {
        width?: GridSize,
        xs?: GridSize,
        sm?: GridSize,
        md?: GridSize,
        lg?: GridSize,
        xl?: GridSize
    }[],
    /**
     * optionally provided function to render sorted children within their column. Can be used to wrap the children within another component, such as a card, table etc.
     *
     * @func
     * @param {node[]} children the children for the current column.
     * @returns {node} the content to render.
     */
    render?: (columnChildren?: React.ReactElement | (React.ReactElement | null)[] | null) => JSX.Element
}

const styles = createStyles<string, ColumnsBaseProps>({
    root: {
        flexWrap: (props) => props.autoWidth ? "nowrap" : undefined
    },
    gridItem: {
        maxWidth: (props) => props.autoWidth ? "unset" : undefined
    }
});
const useStyles = makeOverrideableStyles("Columns", styles);

export interface ColumnsProps extends ColumnsBaseProps, StyledComponentProps<typeof styles> {
}

/**
 * Sorts provided children into columns, according to provided definition, the "column" prop on each child, and the width of the display.
 */
export const Columns = (props: ColumnsProps): JSX.Element => {
    const {className, children, columns, render} = props;
    const classes = useStyles(props);
    return (
        <Grid container className={classNames(className, classes.root)} justifyContent="center">
            {columns && columns.map((column, index) => {
                const extraSmall = column.xs || column.width || 6;
                const small = column.sm || extraSmall;
                const medium = column.md || small;
                const large = column.lg || medium;
                const extraLarge = column.xl || large;
                const columnChildren = filterItemsForColumn(
                    Children.toArray(children).filter((child): child is React.ReactElement => !!child),
                    index,
                    columns.length
                );

                return (
                    <Grid
                        item
                        xs={extraSmall}
                        sm={small}
                        md={medium}
                        lg={large}
                        xl={extraLarge}
                        key={index}
                        className={classes.gridItem}
                    >
                        {render && render(columnChildren) || columnChildren}
                    </Grid>
                );
            }) || (
                <Grid item xs={12} className={className}>
                    {render && render(children) || children}
                </Grid>
            )}
        </Grid>
    );
};

export default Columns;