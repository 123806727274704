import {Card, CardContent} from "@barracuda-internal/bds-core";
import {Grid, GridNoRecords, GridProps} from "@progress/kendo-react-grid";
import React, {memo, ReactElement, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import classNames from "classnames";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import Pager from "../Pager/Pager";

const styles = (theme: Theme) => createStyles<string, CoreTableProps & { calcOffset: number }>({
    card: {
        width: "100%"
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            padding: 0
        },
        position: "relative"
    },
    tableFitToScreen: {
        "& .k-grid-header-wrap": {
            marginRight: 14
        },
        "& .k-grid-content": {
            overflowY: "scroll !important"
        },
        "& .k-grid-container": {
            borderRadius: theme.shape.borderRadius
        },
        "& .k-checkbox": {
            borderColor: theme.palette.primary.main,
            verticalAlign: "middle !important",
            width: "12px",
            height: "12px"
        },
        "& .k-checkbox:checked": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        }
    },
    flat: {
        marginTop: 0
    }
});

const useStyles = makeOverrideableStyles("Table", styles);

// TODO: I've set this type to any, as it wasn't correctly copying over the Props of Grid.
const MemoGrid: any = memo(Grid);

export interface CoreTableProps extends GridProps {
    /**
     * if true, the table is max-sized to 60% of the screen height, so that it comfortably fits within your page (and scrolling is handled at the row level, rather than page level).
     */
    fitToScreen?: boolean,
    /**
     * if true, table is rendered on its own. Otherwise it will be rendered within a Card.
     */
    flat?: boolean,
    /**
     * content to display within table when there is no data to display.
     */
    noRecordsMessage?: ReactNode,
    /**
     * Set the max height. This is otherwise unset, unless you are using fitToScreen.
     * */
    maxHeight?: string | number,
    /**
     * content to render above the table (outside the card, if not "flat").
     */
    tableActions?: ReactNode,
    /**
     * children to provide to the Kendo Grid
     */
    children?: ReactElement | null | (ReactElement | null)[],
}

// seperated out to prevent self-referencing types, as styles is dependent on CoreTableProps too
export interface TableProps extends CoreTableProps, StyledComponentProps<typeof styles> {
}

/**
 * A basic implementation of Kendo Grid, with additional styling and support for no-data message and table actions.
 *
 * All props that are provided that are not described below are passed to the Grid.
 */
const Table = (props: TableProps) => {
    const {children, className, flat, noRecordsMessage, tableActions, fitToScreen, ...tableProps} = props;
    const [calcOffset, setCalcOffset] = useState(400);
    const classes = useStyles({calcOffset, ...props});
    const contentRef = useRef<null | HTMLDivElement>(null);
    const tableChildren: (ReactElement | null)[] = useMemo(() => [
        children || null,
        noRecordsMessage ? (
            <GridNoRecords key="noRecords">
                {noRecordsMessage}
            </GridNoRecords>
        ) : null
    ].flat(), [noRecordsMessage, children]);

    useEffect(() => {
        const currentOffset = (contentRef.current?.getBoundingClientRect?.() || {top: calcOffset}).top;
        if (fitToScreen) {
            setCalcOffset(currentOffset);
        }
    }, [fitToScreen]);

    const tableContent = (
        <CardContent
            className={classNames(classes.cardContent)}
            ref={contentRef}
        >
            <MemoGrid
                pager={Pager}
                className={classNames(fitToScreen && classes.tableFitToScreen, className)}
                style={{maxHeight: props.maxHeight ? props.maxHeight : (fitToScreen ? `max(calc(100vh - ${calcOffset + 64}px), 256px)` : undefined)}}
                {...tableProps}
            >
                {tableChildren}
            </MemoGrid>
        </CardContent>
    );

    return flat ? (
        <div className={classes.flat}>
            {tableActions}
            {tableContent}
        </div>
    ) : (
        <React.Fragment>
            {tableActions}
            <Card className={classes.card} elevation={1}>
                {tableContent}
            </Card>
        </React.Fragment>
    );
};

export default Table;