import {get, merge} from "lodash";
// @ts-ignore
import {stringify} from "query-string";
import {getDataContent} from "../utils";
import performFetch from "./performFetch";

/**
 * CRUD Type
 * The different CRUD process types supported.
 */
export enum CrudTypes {
    GET = "GET",
    ACTION = "ACTION",
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

/**
 * CRUD request parameters.
 */
export interface CrudParams {
    /** CRUD request body */
    data?: any,
    /** CRUD filter. If provided, this object is used to generate a URL query string for filtering, which is added to the request. */
    filter?: {
        [key: string]: any
    },
    /** CRUD pagination. If provided, these values are used to add a URL query for pagination to the request. */
    pagination?: {
        page: number,
        perPage: number
    },
    /** CRUD pagination. If provided, these values are used to add a URL query string for sorting to the request. */
    sort?: {
        field?: string,
        order?: string
    },
    /** Fetch options to override defaults. */
    options?: RequestInit,

    /** all additional properties are used to resolve placeholders in the resource URI */
    [key: string]: any
}

export const AUTHENTICATION_FAILED = "authentication.failed";

const getQuery = (params: CrudParams) => {
    const queryMap = {...(params.filter || {})};

    // Need to add additional entry for any filter that contains a single entry with a "," due to
    // inadvertent string splitting on spring-boot. Adding an extra empty search value forces spring boot to ignore the
    // commas, and split by &entry=&entry=...
    Object.keys(queryMap).forEach((entry: string) => {
        if (Array.isArray(queryMap[entry]) && queryMap[entry].length === 1 && typeof queryMap[entry][0] === "string" && queryMap[entry][0].includes(",")) {
            queryMap[entry].push("");
        } else if (typeof queryMap[entry] === "string" && queryMap[entry].includes(",")) {
            queryMap[entry] = [queryMap[entry], ""];
        }
    });

    if (params.pagination) {
        queryMap.page = params.pagination.page - 1;
        queryMap.size = params.pagination.perPage;
    }

    if (params.sort && params.sort.field) {
        queryMap.sort = params.sort.field + "," + params.sort.order;
    }

    return Object.keys(queryMap).length > 0 ? `?${stringify(queryMap)}` : "";
};

const jsonHeader = {"Content-Type": "application/json;charset=UTF-8"};

export const convertRESTRequestToHTTP = (type: CrudTypes, url: string, params: CrudParams): {
    url: string,
    options: RequestInit
} => {
    const endPoint = replaceEndPointParams(url, params);
    const options: RequestInit = {
        method: "GET"
    };
    const query = getQuery(params);
    const resolvedUrl = `${endPoint}${query}`;

    switch (type) {
        case CrudTypes.GET:
            break;
        case CrudTypes.ACTION:
            options.method = "PUT";
            options.headers = jsonHeader;
            break;
        case CrudTypes.UPDATE:
            options.method = "PUT";
            options.headers = jsonHeader;
            options.body = JSON.stringify(params.data);
            break;
        case CrudTypes.CREATE:
            options.method = "POST";
            options.headers = jsonHeader;
            options.body = JSON.stringify(params.data);
            break;
        case CrudTypes.DELETE:
            options.method = "DELETE";
            options.headers = jsonHeader;
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
    return {
        url: resolvedUrl,
        options: {...options, ...(params?.options || {})}
    };
};

const replaceEndPointParams = (endpoint: string, params: CrudParams) => {
    const regexParams = /{([^}]+)}/g;
    const matches = [];
    let match;

    while ((match = regexParams.exec(endpoint)) !== null) {
        if (match[1] && match[1] !== "") {
            matches.push(match[1]);
        }
    }

    matches.forEach((match) => {
        const replacement = get(params, match);
        const paramPlaceholder = "{" + match + "}";

        // Auto fail any URL that requires authentication provided user data, when the user data is missing.
        if (match.startsWith("userData") && !replacement) {
            throw AUTHENTICATION_FAILED;
        }

        if (replacement || replacement === 0) {
            endpoint = endpoint.replace(paramPlaceholder, replacement.toString());
        } else {
            endpoint = endpoint.replace(paramPlaceholder, "");
        }
    });
    endpoint = endpoint.replace(/\/*$/, "");
    return endpoint;
};

/**
 * Maps CRUD queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * CrudTypes.GET          => CrudTypes.GET https://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * CrudTypes.UPDATE       => CrudTypes.PUT https://my.api.url/posts/123
 * CrudTypes.CREATE       => CrudTypes.POST https://my.api.url/posts/123
 * CrudTypes.DELETE       => CrudTypes.DELETE https://my.api.url/posts/123
 */
const crudClient = <DataModel = any>(type: CrudTypes, url?: string, params?: CrudParams, globalParams?: object, customFetchOptions: object = {}): Promise<{
    data?: DataModel
}> => {
    try {
        const mergedParams = merge({}, params, globalParams);

        if (!url) {
            return Promise.resolve({data: undefined});
        }
        const {url: resolvedUrl, options} = convertRESTRequestToHTTP(type, url, mergedParams);

        // @ts-ignore
        return performFetch<DataModel>(resolvedUrl, merge(options, customFetchOptions))
            .then((response) => {
                if (response.body && typeof response.body !== "string") {
                    const data = {data: response.body};
                    if (Array.isArray(data.data)) {
                        // @ts-ignore
                        data.data = {content: data.data, total: data.data.length};
                    } else if (Array.isArray(getDataContent(data))) {
                        // TODO: Ignoring this line for now. Need to work out how best to type these responses (array response with totals vs JSON vs String)
                        // @ts-ignore
                        data.data.total = (data?.data?.page?.totalElements) || getDataContent(data).length;
                    }

                    if (type === CrudTypes.CREATE || type === CrudTypes.UPDATE) {
                        data.data = merge({}, params?.data, data.data);
                    }

                    return data;
                }
                return {data: response.body};
            }).catch((error) => {
                if (error instanceof SyntaxError) {
                    error.message = "httpError.500";
                }
                return Promise.reject(error);
            });
    } catch (error) {
        return Promise.reject(error);
    }
};

export default crudClient;