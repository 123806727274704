import {TextField, TextFieldProps} from "@barracuda-internal/bds-core";
import React from "react";
import {formatErrorMessage} from "../../../utils";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {get} from "lodash";

export const styles = (theme: Theme) => createStyles({
    textField: {
        width: (props) => get(props, "options.fullWidth") ? "100%" : 256,
        marginTop: theme.spacing(0.5),
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.background.paper,
        }
    }
});
const useStyles = makeOverrideableStyles("Text", styles);

export interface TextProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * error error associated with this input.
     */
    error?: string | string[] | object,
    /**
     * provided by [Input][Input](/?path=/docs/core-components-inputs-input) component. id to assign to the main text component for unique identification. This value is prefixed with "text-input-".
     */
    id: string,
    /**
     * onBlur callback to called when component stops being interacted with.
     * @function
     * @param {object} choice the currently selected choice.
     */
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void,
    /**
     * onChange callback to call when input's value wants to be updated.
     * @function
     * @param {object} choice the currently selected choice.
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    /**
     * onFocus callback to called when input get focus.
     * @function
     */
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    /**
     * additional props to pass to the underlying TextField.
     */
    options?: TextFieldProps,
    /**
     * passed to the TextField component to set it's type.
     */
    type?: string,
    /**
     * the current value.
     */
    value?: string | number,
    /**
     * text to be displayed as a placeholder in the text box.
     * this will be translated.
     */
    placeholder?: string,
    /**
     * text to be displayed as a label for the text box.
     * this will be translated.
     */
    label?: string,
}

/**
 * Based on the TextField component from BDS, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format. Works natively with redux form "input" prop.
 */
export const Text = (props: TextProps) => {
    const {
        id,
        type,
        options,
        onBlur,
        onFocus,
        onChange,
        value,
        error,
        disabled,
        placeholder,
        label
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <TextField
            value={value || ""}
            onBlur={(eventOrValue) => {
                onBlur && onBlur(eventOrValue);
            }}
            onFocus={(event) => {
                onFocus && onFocus(event);
            }}
            onChange={(eventOrValue) => {
                onChange && onChange(eventOrValue);
            }}
            type={(type === "integer" || type === "number") ? "text" : type}
            helperText={formatErrorMessage(error)}
            error={!!error}
            placeholder={placeholder && translate(placeholder)}
            label={label && translate(label)}
            {...options}
            InputProps={{
                ...(options?.InputProps || {}),
                inputProps: {
                    ...(options?.InputProps?.inputProps || {}),
                    onKeyDown: (event) => {
                        if (type === "integer" || type === "number") {
                            const regExp = type === "integer" ? /[^0-9-]/g : /[^0-9-.]/g;
                            if (regExp.test(event.key) && !(event.key === "Backspace") && !(event.key === "Enter") && !(event.key === "Tab")) {
                                event.preventDefault();
                            }
                        }
                    }
                }
            }}
            disabled={disabled}
            id={id && "text-input-" + id}
            className={classes.textField}
            variant="outlined"
        />
    );
};

Text.defaultProps = {
    onBlur: () => {
    },
    onChange: () => {
    },
    onFocus: () => {
    },
    options: {},
    type: "text"
};

export default Text;