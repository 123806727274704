import React, {Component, ReactNode} from 'react';
import {Container, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Theme} from "@mui/material";

export const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
    }
});

const useStyles = makeOverrideableStyles("ErrorBoundary", styles);

export const ErrorDisplay = () => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <Container className={classes.root}>
            <Typography>{translate("cuda.error.message")}</Typography>
        </Container>
    );
};


export type ErrorBoundaryProps = {
    /**
     * the children which should be rendered under normal circumstances
     */
    children?: ReactNode,
    /**
     * if set to true, don't show the error message
     */
    hide?: boolean
};

export type ErrorBoundaryState = {
    hasError: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    render() {
        if (!this.state.hasError) {
            // wrapped in fragment to ensure it's a valid JSX.Element
            return <>{this.props.children}</>;
        }

        return this.props.hide ? null : (
            <ErrorDisplay/>
        );
    }
}

export default ErrorBoundary;