import React, {createContext, ReactNode} from "react";
import {useSharedData} from "../../../hooks";

export type GlobalParamsContextProps = {
    state: Record<string, any>,
    update: React.Dispatch<React.SetStateAction<Record<string, any> | undefined>>
};

export const GlobalParamsContext = createContext<GlobalParamsContextProps>({
    state: {},
    update: () => {
    }
});
export const GlobalParamsDataKey = "GlobalParamsData";
export const GlobalParamsProvider = ({children}: { children: ReactNode }): JSX.Element => {
    const [state, update] = useSharedData<any>(GlobalParamsDataKey);

    return (
        <GlobalParamsContext.Provider value={{state, update}}>
            {children}
        </GlobalParamsContext.Provider>
    );
};