import React, {Children} from "react";
import {TabPanel} from "@barracuda-internal/bds-core";
import classNames from 'classnames';
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    tabPanel: {
        padding: 0,
        "& > div": {
            padding: 0
        }
    },
    tabHidden: {
        display: "none"
    }
});
const useStyles = makeOverrideableStyles("Tab", styles);

export interface TabProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, the tab will always render its content, regardless if it is active or not
     */
    alwaysRender?: boolean,
    /**
     * used alongside value to determine if the tab is active. If activeValue matches the value given to this tab, it is considered as
     * active and the content will be rendered. Automatically passed in from Tabs/SideTabs parents.
     */
    activeValue?: string | number,
    /**
     * the content to render within a TabPanel, when the tab is active.
     *
     * The content is cloned, and provided with a "visible" prop, which is true when active, and false if set to alwaysRender, but not currently active.
     */
    children?: React.ReactElement | null | (React.ReactElement | null)[],
    /**
     *  the unique value associated with this tab. Used alongside activeValue to determine if the tab is active.
     *  If activeValue matches the value given to this tab, it is considered as active and the content will be rendered.
     *
     *  This is also used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component, to set/check for in the page URL's hash, that determines if this tab is opened.
     * If not provided the index of the child will be used, however this can lead to unexpected behaviour if the number or order of children changes.
     * */
    value?: string | number,
    /**
     * if true, the tab content will be hidden by css when it is not active. Can be used with alwaysRender to render the children without displaying it in the DOM.
     */
    setDisplayNone?: boolean,
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * Icon to display in the tab button
     */
    icon?: React.ReactNode,
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * Text to display in the tab button
     */
    label?: string,
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * Text to display as a "status" for the tab
     */
    status?: string,
     /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * icon to display next to the "status" text
     */
    statusIcon?: React.ReactNode,
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * additional props to provide to the status icon.
     */
    statusIconProps?: any,
     /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * or [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * Override the text color for the "status" text
     */
    statusColor?: string,
    /**
     * Prop used by its parent when rendered inside a [Tabs](/?path=/docs/core-components-tabs-tabs--tabs) component.
     *
     * Sets whether the tab should be highlighted as in "error".
     */
    error?: boolean,
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * component.
     *
     * Sets whether the tab should be disabled.
     */
    disabled?: boolean
    /**
     * Prop used by its parent when rendered inside a [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
     * component.
     *
     * Sets a tooltip message to display when disabled.
     */
    disabledMessage?: string
}

/**
 * A single tab implementation. Intended as a child for [SideTabs](/?path=/docs/core-components-tabs-sidetabs--side-tabs)
 * and [Tabs](/?path=/docs/core-components-tabs-tabs--tabs).
 *
 * The behaviour for tabs is determined by the parent (Tabs or SideTabs), see so their respective pages to view what additionally props
 * should be added.
 */
export const Tab = (props: TabProps) => {
    const {children, value, activeValue, alwaysRender, setDisplayNone} = props;
    const classes = useStyles(props);
    const childArray = Children.toArray(children).filter((child): child is React.ReactElement => !!child);

    return (
        <TabPanel className={classNames(classes.tabPanel, value !== activeValue && setDisplayNone && classes.tabHidden)}>
            {(value === activeValue || alwaysRender)
                ? childArray.map((child, index) => (
                    <ErrorBoundary hide={activeValue !== value} key={index}>
                        {React.cloneElement(child, {visible: activeValue === value})}
                    </ErrorBoundary>
                ))
                : null
            }
        </TabPanel>
    );
};

export default React.memo(Tab);