import React, {useCallback} from "react";
import {CrudTypes} from "../../../clients";
import {useCrudFetch, useCrudProps, useDeepCompareMemoize} from "../../../hooks";
import {getDataContent} from "../../../utils";
import Form, {FormProps} from "../Form/Form";
import TabbedForm, {TabbedFormProps} from "../TabbedForm/TabbedForm";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles} from "@cuda-react/theme";

const styles = createStyles({
    paper: {
        flex: "1 1 auto",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column"
    }
});
const useStyles = makeOverrideableStyles("ConnectedForm", styles);

export interface ConnectedFormCommonProps {
    /** if true, a [CREATE CRUD](/?path=/docs/cudareactapp-crud--page) request is used, ignored when the `query` prop is set to true */
    create?: boolean,

    /** if true, a [TabbedForm](/?path=/story/core-components-forms-tabbedform--tabbed-form) is created instead of a Form */
    tabbed?: boolean,

    /** the resource to fetch/update data from */
    resource: string | undefined,

    /** CRUD id to include the GET/CREATE/UPDATE request params */
    id?: number | string,

    /** initial values for the form, if set no values will be received from CRUD */
    initialValues?: any,

    /** the CRUD resource to use in a GET request to fetch the initial form values (when create is true) */
    defaultResource?: string,

    /** if true, the form is not rendered inside a card */
    flat?: boolean,

    /**
     * it handles the error when the save action is triggered on a connected form
     *
     * @param error the ReferenceError
     * @param values the data that needs to be parsed to be saved
     * @param formattedData the object containing the data formatted
     * @returns the formatted data.
     */

    formatError?: (error: any, values: any, formattedData: any) => any,
    /**
     * it takes as input the values and creates the formattedData ready to submit via crudSubmit
     *
     * @param values the values that need to be formatted
     * @returns the formatted data.
     */

    formatRequestData?: (values: any) => any,
    /**
     * it creates the initial values that need to be displayed as form content
     *
     * @param initialValues the initial values
     * @returns the formatted data.
     */
    formatResourceData?: (initialValues: any) => any,

    /** params to use when fetching data via a [CRUD](/?path=/docs/key-concepts-crud) subscription */
    params?: any,

    /** if true, a [GET CRUD](/?path=/docs/cudareactapp-crud--page) request is used, this will override the `create` prop when set to true */
    query?: boolean,
    /**
     * callback called when the form has been successfully submitted.
     * @param responseData the response data received from the server
     */
    onSubmitSuccess?: (responseData: any) => void
}

export type ConnectedFormProps =
    (({ tabbed: true } & TabbedFormProps) | ({ tabbed?: false } & FormProps))
    & ConnectedFormCommonProps;

/**
 * Connects Forms to CRUD resources
 *
 * Passing the props create, it creates a new resource with empty values
 * Passing the props create with defaultResource, it creates a new resource with filled values
 *
 * Also accepts any props used by [Form](/?path=/docs/core-components-forms-form--form)/[TabbedForm](/?path=/docs/core-components-forms-tabbedform--tabbed-form)
 * and [useForm](/?path=/story/core-hooks-form-hooks--page)
 *
 */
export const ConnectedForm = (props: ConnectedFormProps): JSX.Element => {
    const {
        children,
        create,
        tabbed,
        resource,
        id,
        defaultResource,
        formatError,
        formatRequestData,
        formatResourceData,
        initialValues: userInitialValues,
        query,
        params = {},
        onSubmitSuccess,
        ...formProps
    } = props;
    const classes = useStyles(props);
    const initialResource = !userInitialValues && ((create || query) ? defaultResource : resource);
    const [, saving, crudSubmit] = useCrudFetch(query ? CrudTypes.GET : (create ? CrudTypes.CREATE : CrudTypes.UPDATE), resource, {id, ...params});
    const [initialData, loading, fetchInitialData] = useCrudProps(initialResource || undefined, {id, ...params});
    const initialValues = userInitialValues || getDataContent(initialData) || {};

    const save = useCallback((values) => {
        const formattedData = formatRequestData && formatRequestData(values) || values;
        return crudSubmit(
            {data: formattedData, id, ...params},
            {formPromise: true}
        ).then((response) => {
            if (!response?.error) {
                fetchInitialData();
                onSubmitSuccess?.(response?.data);
            }
            return response?.data;
        }).catch((error) => {
            throw formatError ? formatError(error, values, formattedData) : error;
        });
    }, [formatRequestData, formatError, crudSubmit]);

    const initialValuesMemo = useDeepCompareMemoize(formatResourceData ? formatResourceData(initialValues) : initialValues);
    const formContent = React.createElement(
        // @ts-ignore not sure why typescript generates this error
        tabbed ? TabbedForm : Form,
        {
            ...formProps,
            initialValues: initialValuesMemo,
            save,
            disabled: loading || saving
        },
        children
    );

    return (
        <div>
            {formContent}
        </div>
    );
};

export default ConnectedForm;