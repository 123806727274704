import {get, isEqual, isEqualWith} from "lodash";
import React, {useEffect, useMemo, useRef, useState} from "react";

export const isChild = (item: any) => get(item, "$$typeof", "").toString().startsWith("Symbol");

export const simplifyChild = ({key, type, props: {children, ...props} = {}}: {
    key?: any,
    type?: any,
    props?: { children?: any }
}) =>
    ({key, type, props: {children: children && React.Children.toArray(children), ...props}});
export const customChildIsEqual = (valueA: any, valueB: any): boolean | undefined => {
    const resolveA = isChild(valueA) && simplifyChild(valueA);
    const resolveB = isChild(valueB) && simplifyChild(valueB);
    return (resolveA || resolveB) ? isEqualWith(resolveA || valueA, resolveB || valueB, customChildIsEqual) : undefined;
};

/**
 * Hook that returns original value if it has not changed (via deep compare), useful to prevent shallow compare issues
 *
 * @function
 * @param {*} value the value for the current render.
 * @returns {*} the memoized value.
 */
export const useDeepCompareMemoize = <InputType = any>(value: InputType): InputType => {
    const ref = useRef<InputType>(value);

    if (!isEqualWith(value, ref.current, customChildIsEqual)) {
        ref.current = value;
    }

    return ref.current;
};

/**
 * Hook that works in the same way as "useMemo" but implements a deep compare of the dependencies instead of a shallow one.
 *
 * @function
 * @param {function} callback callback, as per useMemo.
 * @param {Array.<*>} dependencies array of dependencies.
 * @returns {value} the result of the callback from the last time it was ran.
 */
export const useDeepCompareMemo = <T>(callback: () => T, dependencies: any[]) => useMemo<T>(callback, useDeepCompareMemoize(dependencies));

/**
 * Hook that works in the same way as "useCallback" but implements a deep compare of the dependencies instead of a shallow one.
 *
 * @function
 * @param {function} callback callback, as per useCallback.
 * @param {Array.<*>} dependencies array of dependencies.
 * @returns {value} the result of the callback from the last time it was ran.
 */
export const useDeepCompareCallback = (callback: (...args: any[]) => any, dependencies: any[]) => {
    const refDependencies = useRef(dependencies);
    const refCallback = useRef(callback);
    if (!isEqualWith(refDependencies.current, dependencies, customChildIsEqual)) {
        refDependencies.current = dependencies;
        refCallback.current = callback;
    }
    return refCallback.current;
};

/**
 * Hook that works in the same way as "useEffect" but implements a deep compare of the dependencies instead of a shallow one.
 * Also exposes third optional param "runOnMount" which is defaulted to true. Set to false if you do not want to run the callback
 * on the initial load (so it only runs on CHANGES to the dependencies).
 *
 * @function
 * @param {function} callback
 * @param {Array.<*>} dependencies array of dependencies.
 * @param {boolean} runOnMount sets whether to run the useEffect on the first mount or not. Default is true.
 */
export const useDeepCompareEffect = (callback: Function, dependencies: any[], runOnMount = true) => {
    const allowRun = useRef(runOnMount);

    useEffect(() => {
        if (allowRun.current) {
            return callback();
        }
        allowRun.current = true;
    }, useDeepCompareMemoize(dependencies));
};

/**
 * Hook that returns the provided value as it was during the previous render.
 *
 * @function
 * @param value the current render value.
 * @returns value from the previous render.
 */
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T | undefined>();
    const oldValue = ref.current;
    ref.current = value;
    return oldValue;
};

/**
 * Hook that allows saving a persistent value against a provided key using the browsers localstorage.
 * usage is similar to that of useState
 *
 * @function
 * @param uniqueId a unique identifier to use with localStorage.
 * @param defaultValue the default value if none is already found and nothing has been set yet.
 * @returns {Array} [the current value, a function to set a new value, whether value has been loaded from state for the first time yet]
 */
export const usePersistedState = <StateValueType>(uniqueId: string, defaultValue?: StateValueType): [StateValueType | undefined, (newValue: StateValueType) => void] => {
    const {localStorage} = window;
    const initialSavedValue = useMemo(() => {
        const savedValue = localStorage.getItem(uniqueId);
        try {
            return savedValue ? JSON.parse(savedValue) : undefined;
        } catch {
            return undefined;
        }

    }, []);
    const [value, setLiveValue] = useState<StateValueType | undefined>(initialSavedValue === undefined ? defaultValue : initialSavedValue);

    const setValue = (newValue?: StateValueType) => {
        if (!isEqual(newValue, value)) {
            localStorage.setItem(uniqueId, JSON.stringify(newValue));
            setLiveValue(newValue);
        }
    };

    return [value, setValue];
};