import merge from "lodash/merge";
import {makeStyles} from "@mui/styles";
import CudaTheme from "../themes/CudaTheme";
import * as React from "react";
import {ClassKeyOfStyles, ClassNameMap, StyleRulesCallback, Styles} from "@mui/styles/withStyles/withStyles";
import {Theme} from "@mui/material";

type DefaultCudaTheme = Theme & typeof CudaTheme;
/**
 * Method for creating styles that can be overridden in the same manor as per Material UI components
 *
 * @param name the unique name used for overriding this component via the theme (this will be prefixed with "CR" for CudaReact)
 * @param styles material-ui styles object or function returning styles object.
 * @returns styles function as suitable to be passed into withStyles()
 */
export const overrideableStyles = <Theme = DefaultCudaTheme,
    Props extends object = {}, ClassKey extends string = string>(name: string, styles: Styles<Theme, Props, ClassKey>): StyleRulesCallback<Theme, Props, ClassKey> => (theme) => {
    const resolvedStyles = typeof styles === "function" ? styles(theme) : styles;
    // @ts-ignore
    const overridingStyles = theme.overrides && theme.overrides["CR" + name];

    return merge(resolvedStyles, overridingStyles);
};

export type makeOverrideableStylesReturns<Props extends object, ClassKey extends string> = keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
    // eslint-disable-next-line no-unused-vars
    ? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
    // eslint-disable-next-line no-unused-vars
    : (props: Props) => ClassNameMap<ClassKey>

export const makeOverrideableStyles = <Theme = DefaultCudaTheme,
    Props extends object = {}, ClassKey extends string = string>(name: string, styles: Styles<Theme, Props, ClassKey>): makeOverrideableStylesReturns<Props, ClassKey> =>
    makeStyles(overrideableStyles(name, styles), {name: "CR" + name});

export interface StyledComponentProps<StylesType extends Styles<any, any, any> = {}> {
    classes?: Partial<ClassNameMap<ClassKeyOfStyles<StylesType>>>;
    innerRef?: React.Ref<any>;
}