import {makeStyles} from '@mui/styles';

export default makeStyles((theme: any) => ({
        icon: {
            width: theme.spacing(3),
            height: theme.spacing(3)
        },
        disabledIcon: {
            opacity: '0.3'
        },
        itemsPerPage: {
            marginLeft: theme.spacing(8)
        },
        dots: {
            color: theme.palette.text.primary,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    }));