import React, {Children, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import Tab from "../../tabs/Tab/Tab";
import Tabs from "../../tabs/Tabs/Tabs";
import FormButtonToolbar from "../FormButtonToolbar/FormButtonToolbar";
import FormErrorReporter from "../FormErrorReporter";
import FormProvider from "../FormProvider/FormProvider";
import {CommonFormProps} from "../interfaces";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {Paper} from "@barracuda-internal/bds-core";

export const styles = (theme: Theme) => createStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflowY: "auto"
    },
    paper: {
        marginBottom: 2
    }
});

const useStyles = makeOverrideableStyles("TabbedForm", styles);

export type TabbedFormChild = JSX.Element & {
    props: {
        /** if set, this label is rendered in the tab */
        label?: string,
        /** if set, the icon is rendered in the tab */
        icon?: React.ReactNode,
    }
}

export interface TabbedFormProps extends CommonFormProps {
    /**
     * for each child provided, one tab is created.
     *
     * Label, icon and details for each created tab can be set on the child
     *
     * @param {string} child.props.label
     */
    children?: React.ReactNode | TabbedFormChild | TabbedFormChild[];
    /**
     * boolean that when is true renders the toolbarButton on the topRight corner
     */
    topToolbar?: boolean
}

/**
 * A form with several tabs connected to redux
 * Valid children of this component are 1 or more Tab, each containing Inputs, *Step components used in [Wizards](/?path=/docs/core-components-wizard-wizard--wizard), or any element
 * Also accepts all props for [useForm](/?path=/docs/core-hooks-form-hooks--page#)
 */
export const TabbedForm = (props: TabbedFormProps): JSX.Element => {
    const {
        children,
        disabled,
        formButtonToolbarProps,
        noToolbar,
        topToolbar
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const childArray = Children.toArray(children) as Array<React.ReactElement | null>;
    const [tabsWithErrors, setTabsWithErrors] = useState<number[]>([]);

    const setTabError = useCallback((value, error) => {
        setTabsWithErrors((currentValues) => [...currentValues.filter((currentValue) => currentValue !== value), ...(error ? [value] : [])]);
    }, []);

    return (
        <FormProvider {...props} >
            <form className={classes.form}>
                {topToolbar &&
                    <FormButtonToolbar
                        {...formButtonToolbarProps}
                        buttonsOnly
                    />
                }
                <Paper className={classes.paper}>
                    <Tabs>
                        {childArray.map((tab, index) =>
                            tab ? (
                                <Tab
                                    key={tab.props.label || index}
                                    label={translate(tab.props.label)}
                                    value={index}
                                    icon={tab.props.icon}
                                    error={tabsWithErrors.includes(index)}
                                    disabled={disabled || tab.props.disabled}
                                    alwaysRender
                                    setDisplayNone
                                >
                                    <FormErrorReporter value={index} setError={setTabError}>
                                        {tab.props.children}
                                    </FormErrorReporter>
                                </Tab>
                            ) : null
                        )}
                    </Tabs>
                    {!noToolbar && !topToolbar && (
                        <FormButtonToolbar
                            {...formButtonToolbarProps}
                        />
                    )}
                </Paper>
            </form>
        </FormProvider>
    );
};

TabbedForm.defaultProps = {
    submitOnEnter: true,
    pristine: false
};

export default TabbedForm;